

//this is a research idea for creating a data proxy wrapper for the data.
//it allows us to inercept any calls to a data hierarchy and see if anything changed anywhere.
//any child object assigned to the data will also be automatically wrapped in a proxy, etc.
//Even if I do not use this anywhere, keep this class so we don't lose the code.
//The newer version (top of this file) allows a subscription and callback for any changes to the data.
//Adds to each object in the hiearchy, into an object on each node named __meta, that needs to be removed if converting to json, etc.
//  dirty: boolean
//  parent: object (except for the root object)
//  IsDirty(): boolean - returns true if any object in the hierarchy is dirty from that node downward
//  GetRoot(): traverses up the hierarchy to find the root object
//  ClearDirty(): void - sets dirty to false on all objects in the hierarchy, from that node downward
//  callback:any:undefined - the Callback passed in that gets called anytime a value changes.
//  ToJson():string - returns a json string of the object, removing the __meta object from each node.
//  GetRawObjectNoProxy:object - returns the raw object without the proxy on it. Setting values on here bypasses events but if you set an 'object' onto it, that object won't get wrapped in a proxy but should

import { mitt } from "./customMitt";
import { DataProxyCallbackEvents, IDataProxyCallbackData, IDataProxyCallbackDataWithReturn, IProxyData } from "./interfaces";
import * as common from "./commonFunctions";



export function GetDataProxy(forObject):IProxyData{
    return InitializeRecursive(forObject);
}


function InitializeRecursive(obj:IProxyData):IProxyData{
    let rawNoProxyRef;
    let proxyRef;

    try{

        //grab or create the proxy. and grab the raw object if the obj passed in already is a proxy.
        //everything we 'set' onto the object here must bypass the proxy so no events are rasied, etc.
        if (obj['__testIfHasProxy'] == undefined){
            if (obj.__meta == null){
                obj.__meta = {} as any;
                obj.__meta.IsDirty = IsDirty.bind(obj);
                obj.__meta.ClearDirty = ClearDirty.bind(obj);
                obj.__meta.ToJson = ToJson.bind(obj);
                obj.__meta.GetRoot = GetRoot.bind(obj);
                obj.__meta.GetRawObjectNoProxy = GetRawObjectNoProxy.bind(obj);
                obj.__meta.emitter = mitt<DataProxyCallbackEvents>();
            }
            rawNoProxyRef = obj;
            proxyRef = new Proxy(obj, new Property());
        }
        else{
            rawNoProxyRef = obj.__meta.GetRawObjectNoProxy();
            proxyRef = obj;
        }
        rawNoProxyRef.__meta.dirty = false;//and add dirty to each object ensuring all are false.

        //loop through all the fields to find any objects/arrays and wrap them with a proxy recursively
        for (var field in rawNoProxyRef){
            if (field != '__meta'){
                var t = typeof rawNoProxyRef[field];
                if (t == 'object' && !isDate(rawNoProxyRef[field])){
                    if (rawNoProxyRef[field].constructor.name == 'Array'){
                        for (var i=rawNoProxyRef[field].length-1; i>=0; i--){
                            if (typeof rawNoProxyRef[field][i] === 'object'){
                                //replace the item in this array with a proxy.
                                let childProxy = GetDataProxy(rawNoProxyRef[field][i]);
                                rawNoProxyRef[field][i] = childProxy;
                                rawNoProxyRef[field][i].__meta.parent = rawNoProxyRef;
                                //add a listener for any data changes on this child and re-raise it
                                //so that a listener to this event on this object will receive any changes to children
                                //or to 'this obj' since we'll also raise it when data changes on this obj
                                childProxy.__meta.emitter.on('dataChangedIncludingChildren', (data) => {
                                    rawNoProxyRef.__meta.emitter.emit('dataChangedIncludingChildren', data);
                                });
                            }
                        }
                        //now also replace the array itself with a proxy.
                        //any changes to the array will just cause 'this obj' to be dirty and raise the two data changed events on 'this obj'.
                        let arrayProxy:[] = CreateObservableArray(rawNoProxyRef[field], rawNoProxyRef);
                        rawNoProxyRef[field] = arrayProxy;
                    }
                    else {
                        rawNoProxyRef[field] = GetDataProxy(rawNoProxyRef[field]);
                        rawNoProxyRef[field].__meta.parent = rawNoProxyRef;
                    }
                }
            }
        }

        return proxyRef;
    }catch(e){
        console.error(e);
        let restart = false;
        debugger;
        if (restart){
            return InitializeRecursive(obj);
        }
        throw e;
    }

}

function GetRawObjectNoProxy(){
    return common.GetDataProxyTarget(this);
}

function ToJson(){
    return JSON.stringify(this, function(key, value){
        if (key == '__meta')
            return undefined;
        return value;
    });
}

function GetRoot():IProxyData{
    //loop up the hierarchy to find the root object.
    var parent = this;
    while (parent.__meta.parent){
        parent = parent.__meta.parent;
    }
    return parent;
}

function ClearDirty(){
    this.__meta.dirty = false;
    for (var field in this){
        if (field != '__meta'){
            var t = typeof this[field];
            if (t == 'object'){
                if (this[field].constructor.name == 'Array'){
                    for (var obj of this[field]){
                        if (typeof obj === 'object'){
                            obj.__meta.ClearDirty();
                        }
                    }
                }
                else {
                    this[field].__meta.ClearDirty();
                }
            }
        }

    }     
}

/**
 * Recursively checks if any object in the hierarchy is dirty.
 * @returns {boolean} true if any object in the hierarchy is dirty.
 */
function IsDirty(){
    if (this.__meta.dirty)
        return true;//saves from recursing further

    for (var field in this){
        if (field != '__meta'){
            var t = typeof this[field];
            if (t == 'object'){

                if (this[field].constructor.name == 'Array'){
                    for (var obj of this[field]){
                        if (obj.__meta.dirty){
                            return true;//saves from recursing further (should be just calls on the array
                        }
                        if (obj.__meta.IsDirty()){
                            return true;
                        } 
                    }
                }
                else
                {
                    if (this[field].__meta.dirty){
                        return true;//saves from recursing further
                    }

                    if (this[field].__meta.IsDirty()){//recurse
                        return true;
                    }
                }
            }
        }
    }

    return false;
}

function isDate(variable: any): boolean {
    return variable instanceof Date;
}

class Property{
    get (target:IProxyData, prop, receiver){

        //If an object has a proxy on it, this field will always return the raw object,
        //otherwise it would return undefined.
        if (prop == '__testIfHasProxy'){
            return target;
        }

        if (typeof target[prop] === 'function') {
            //pass through to call a function on the object.
            return new Proxy(target[prop], {
              apply: (target, thisArg, argumentsList) => {
                return Reflect.apply(target, thisArg, argumentsList);
              }
            });
        } else {
            //pass through to get a property of the object.
            return Reflect.get(target, prop);
        }
    }

    set (obj:IProxyData, prop, value){
        if (prop == '__meta'){
            //set it but don't replace it
            if (obj.__meta == undefined){
                obj.__meta = value;
            }
        }

        var oldValue = obj[prop];
        if (oldValue != value){

            //raise an event here to notify any subscribers of the change.
            // raise the events for the change.
            let callbackResult:IDataProxyCallbackDataWithReturn = {
                nodeChanged: obj,
                fieldName: prop as string,
                valueBefore: oldValue,
                valueAfter: value
            };          
            obj.__meta.emitter.emit('dataChanged', callbackResult);//to anyone listening for changes 'just' on this array (which incl this array's parent object's parent)
            if (callbackResult.returnAction !== undefined) {
                value = callbackResult.returnAction;//change it to whatever they said it should be.
                if (value == oldValue){
                    return;//and bail since was cancelled by sending back the same value
                }
            }
            obj.__meta.emitter.emit('dataChangedIncludingChildren', callbackResult);



            //check this again, since could be altered by the callback.
            if (oldValue != value){
                if (typeof value === 'object' && !isDate(value)){

                    if (value.constructor.name == 'Array'){
                        //each item in the array needs to be a proxy.
                        for (var i=value.length-1; i>=0; i--){
                            if (typeof value[i] === 'object'){
                                value[i].__meta = {};//if already exists, replace it.
                                value[i].__meta.parent = obj;
                                let childProxy = GetDataProxy(value[i]);
                                value[i] = childProxy;
                                childProxy.__meta.emitter.on('dataChangedIncludingChildren', (data) => {
                                    obj.__meta.emitter.emit('dataChangedIncludingChildren', data);
                                });                                
                            }
                        }
                        //if an array is being assigned, then wrap it in a proxy.
                        //we don't add a listener to the array itself, since it will just raise the events from 'this obj' that's passed into it.
                        value = CreateObservableArray(value, obj);
                    }
                    else {
                        //then a new child object is being assigned, so wrap it also in a proxy.
                        value.__meta = {};//if already exists, replace it.
                        value.__meta.parent = obj;
                        let childProxy = GetDataProxy(value);
                        value = childProxy;
                        childProxy.__meta.emitter.on('dataChangedIncludingChildren', (data) => {
                            obj.__meta.emitter.emit('dataChangedIncludingChildren', data);
                        });             
                    }
                }

                obj[prop] = value;
                obj.__meta.dirty = true;
            }
        }
        return true;
    }
}

export function CreateObservableArray(
    arr: [],
    parent: IProxyData
  ): [] {

    if (arr['__testIfHasProxy'] != undefined){
        return arr;//already a proxy
    }

    const mutatingMethods = [
      'push',
      'pop',
      'shift',
      'unshift',
      'splice',
      'reverse',
      'sort',
      'fill',
      'copyWithin',
    ];
  
    function isArrayIndex(prop: string | symbol): boolean {
      if (typeof prop === 'symbol') return false;
      const index = Number(prop);
      return String(index) === prop && index >= 0 && Number.isInteger(index);
    }
  
    const handler: ProxyHandler<[]> = {
      get(target, property, receiver) {

        //If an array has a proxy on it, this field will always return true,
        //otherwise it would return undefined.
        if (property == '__testIfHasProxy'){
            return target;
        }


        const value = Reflect.get(target, property, receiver);
  
        if (typeof property === 'string' && mutatingMethods.includes(property)) {
          return function (...args: any[]) {
            const before = target.slice(); // Copy before state

            // Simulate the operation on a copy to get the after state
            const after = before.slice();
            const simulatedResult = (after as any)[property](...args);
  
            // raise the events for the change.
            let callbackResult:IDataProxyCallbackDataWithReturn = {
                nodeChanged: parent,
                fieldName: property,
                valueBefore: before,
                valueAfter: after
            };
            //changes to this array just get sent directly 'from' the parent object, the array doesn't bother to have
            //its own __meta or emitter. And so it also doesn't listen for changes from child objects since the parent is already
            //listening to them.
            parent.__meta.emitter.emit('dataChanged', callbackResult);//to anyone listening for changes 'just' on this array (which incl this array's parent object's parent)
            if (callbackResult.returnAction === false) {
                return;//cancelled.  An array returns 'false' to cancel the operation.
            }
            parent.__meta.emitter.emit('dataChangedIncludingChildren', callbackResult);//to anyone listening for changes on this array or any child objects.

            if (Array.isArray(callbackResult.returnAction)) {
                parent.__meta.dirty = true;
                // Replace the array's contents with the new array
                target.length = 0;
                target.push(...callbackResult.returnAction as []);
                // we need to ensure each item in this array is a proxy.
                for (var i=target.length-1; i>=0; i--){
                    if (typeof target[i] === 'object'){
                        if (target[i]['__testIfHasProxy'] == undefined){
                            (target[i] as any).__meta = {};//doesn't exist yet.
                        }
                        (target[i] as any).__meta.parent = parent;
                        (target[i] as any) = GetDataProxy(target[i]);
                    }
                }
                return; // Return undefined or appropriate value
            }
            else {
                (parent as any).__meta.dirty = true;
                // Proceed with the original operation
                const result = (value as Function).apply(target, args);
                //if this is a 'push' operation, then we need to wrap the new object in a proxy.
                if (property == 'push'){
                    for (var i=args.length-1; i>=0; i--){
                        if (typeof args[i] === 'object'){
                            if (args[i]['__testIfHasProxy'] == undefined){
                                args[i].__meta = {};//doesn't exist yet.
                            }
                            args[i].__meta.parent = parent;
                            args[i] = GetDataProxy(args[i]);
                        }
                    }
                }
              return result;
            }
          };
        }
  
        return value;
      },

      set(target, property, value, receiver) {
        if (isArrayIndex(property) || property === 'length') {
            const before = target.slice();
            const result = Reflect.set(target, property, value, receiver);
            const after = target.slice();

            // raise the events for the change.
            let callbackResult:IDataProxyCallbackDataWithReturn = {
                nodeChanged: parent,
                fieldName: property as string,
                valueBefore: before,
                valueAfter: after
            };          
            parent.__meta.emitter.emit('dataChanged', callbackResult);//to anyone listening for changes 'just' on this array (which incl this array's parent object's parent)
            if (callbackResult.returnAction === false) {
                // Revert the change
                target.length = 0;
                target.push(...before);
                return false;
            }
            parent.__meta.emitter.emit('dataChangedIncludingChildren', callbackResult);

            if (Array.isArray(callbackResult.returnAction)) {
                // Replace the array's contents
                target.length = 0;
                target.push(...callbackResult.returnAction as []);
                // we need to ensure each item in this array is a proxy.
                for (var i=target.length-1; i>=0; i--){
                    if (typeof target[i] === 'object'){
                        if (target[i]['__testIfHasProxy'] == undefined){
                            (target[i] as any).__meta = {};//doesn't exist yet.
                        }
                        (target[i] as any).__meta.parent = parent;
                        (target[i] as any) = GetDataProxy(target[i]);
                    }
                }                
                return true;
            } else {
                return result;
            }
        } else {
          return Reflect.set(target, property, value, receiver);
        }
      },
    };
  
    return new Proxy(arr, handler) as [];
  }
  

 