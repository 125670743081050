import * as common from './commonFunctions';
import { CollectionType } from './baseFirestoreObjects';
import { IDocument, IDocumentData, ISummaryData } from './interfaces';
import { BaseFirestoreDoc } from './baseFirestoreDoc';





/* this is all for a suggest alternative to the above. Define it all with json instead of functions */

export interface IDocSchemaDefinition{
    type:CollectionType;
    parentType:CollectionType;
    summaries?:ISummarySchema[];
    childFields?:DocFieldsDefinition;
}

export interface IDocSchema extends IDocSchemaDefinition{
    /**
     * This needs to be set to the actual class itself, like say Client with no quotes. It's a ref to the class template
     * so it can be used to create new instances of the class. This value cannot be stored in a json text file; needs to be set at runtime.
     * It links the text name of the class (ie, 'type') to the actual javascript class template.
     */
    classJavascriptType:any;
}

// just defines a list of child properties, which is basically the doc's definition
export type DocFieldsDefinition = IChildObject[] | IChildArray[];

export interface IChildProperty{
    fieldName:string;
    //just a common base of the two below
}

export interface IChildObject extends IChildProperty{
    defaultValue?:any;//can't have both defaultValue and children at the same time. one or the other.
    childFields?:DocFieldsDefinition;
    allowNull?:boolean;
}

export interface IChildArray extends IChildProperty{
    /**
     * Field name of the unique id for each item in the array so the system and sync them up with data being merged together.
     * Set this to null if the items in the array are not objects but just strings or numbers, etc.
     */
    keyFieldName;//MUST BE REQUIRED. This is also the only way to differential between this and an IChildObject. normally just zero.
    childFields?:DocFieldsDefinition;
}

export interface ISummarySchema{
    fieldName:string;
    childType:CollectionType;
    maps:(IMapSchema|ISummarySchema|IMapCustomCodeSchema|IMapSchemaSameName)[];
}

export interface IMapSchema{
    parentName:string;

    /**
     * Specity just a field name on the root or a simple path notation such as 'parent.child.grandchild.firstName'.
     * This DOES NOT support looking in arrays; use the custom code map option for that.
     * 
     */
    childPath:string;
}

export interface IMapSchemaSameName{
    fieldName:string;
}

export interface IMapCustomCodeSchema{
    customCode:(params:IMapCustomCodeParams)=>void;
}

export interface IMapCustomCodeParams{
    parentData:IDocumentData;
    childData:IDocumentData;
    summaryItem:ISummaryData;
    summarySchema:ISummarySchema
}

let test:IDocSchemaDefinition = {
    type:"clients",
    parentType:null,//client doesn't have a parent in this case. this is the collection name 'string'
    summaries:[
        {
        fieldName:'sections',
        childType:'sections',
        maps:[
            {
                fieldName:'name',//no need to specify childName if same as parentName
            },
            {
                fieldName:'requests',//this object is another summary to a grandchild document collection
                childType:'gptRequests',
                maps:[
                    {
                        fieldName:'id',
                    },
                    {
                        parentName:'name',
                        childPath: 'theName'//parent and child names aren't the same
                    }
                ]
            },
            {
                customCode:(params:IMapCustomCodeParams)=>{
                    //do something custom
                }
            }
        ]
    }
    ],
    childFields:[
        {
            fieldName:'imageInfo',
            allowNull:true,//can be left null
            childFields:[
                {
                    fieldName:'colourStuff',//will never be null
                }
            ]
        } as IChildObject,
        {
            fieldName: 'phoneNumbers',//this object defines an array
            keyFieldName:'number',//(unique key for item) if left null, then items in this array are not objects but just strings or numbers, etc.
            childFields:[
                {
                    fieldName:'number',
                    defaultValue:'604-555-5555'
                } as IChildObject
            ]
        } as IChildArray
    ]
}

export type Schema = {[key:string]:IDocSchema};

//define a variable named docs as a dictionary with key of string and type IDocSchemaDefinition
export let definitions:Schema = {};
 
export function Doc(classType:any, schemaDoc:IDocSchemaDefinition){
    //add the class type to the schema. We don't make them put it in the schema
    //since it's the only non-text thing in the json, allowing us to potentially store all 
    //the schema as pure json in a file, but the caller will have to loop through it and call this function
    //for each one so we can add the class type.
    let docSchema:IDocSchema = schemaDoc as any;
    docSchema.classJavascriptType = classType;
    definitions[schemaDoc.type] = docSchema;
}


/**
 * The website typically has a library of model classes that register themselves with the schema in this base-library,
 * but then the website also uses the client-lib which also makes use of this base-library,
 * but those are two separate instances of the base-library where only one has the schema defined. So the website's main firestore
 * service needs to grab the schema from here ans pass it over by calling this function.
 * @param schema 
 */
export function SetSchema(schema:Schema){
    definitions = schema; 
}



