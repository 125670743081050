import React, { useState } from "react";
import styles from "./card.module.css";
import { useNavigate } from "react-router-dom";
import Modal from "../modalConfirmation/modal-confirmation.component";

//define a funtion type that returns a value but takes no arguments
export type CardDataFunction<T> = (data:T) => any;

export class CardDataAccessor<T>{
  constructor(fieldAccessors:CardDataType<T>)
  {
    this.id = fieldAccessors.id;
    this.title = fieldAccessors.title;
    this.content = fieldAccessors.content;
    this.url = fieldAccessors.url;
    this.backgroundImage = fieldAccessors.backgroundImage;
    this.backgroundImageHover = fieldAccessors.backgroundImageHover;
  }

  id:CardDataFunction<T>;
  title:CardDataFunction<T>;
  url:CardDataFunction<T>;
  backgroundImage:CardDataFunction<T>;
  backgroundImageHover:CardDataFunction<T>;
  content:CardDataFunction<T>;
}

type CardDataType<T> = {
  id:CardDataFunction<T>;
  title:CardDataFunction<T>;
  url:CardDataFunction<T>;
  backgroundImage:CardDataFunction<T>;
  backgroundImageHover:CardDataFunction<T>;
  content:CardDataFunction<T>;
}

export interface CardProps {
  cardDataAccessor:CardDataAccessor<any>;
  data:any;
  isHidden?:string;//useful to fill in space in a grid on just the last page if only has a few cards so the controls on the bottom don't jump around
  isNew?:boolean;
  handleDeleteConfirmation?: (data:any) => Promise<void>;
}

const Card: React.FC<CardProps> = ({cardDataAccessor, data, isHidden, isNew, handleDeleteConfirmation}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalHeading, setModalHeading] = useState('');

  let navigate = useNavigate();
  const url = ''

  const handleClick = () => {
    navigate(cardDataAccessor.url(data) + `?id=${cardDataAccessor.id(data)}`);
  };

  function getValue(){
    return "";
  }

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();

    setModalHeading('Delete Card');
    setModalMessage('Are you sure you want to delete this card?');
    setIsModalOpen(true);
  }

  const deleteConfirmed = () => {
    handleDeleteConfirmation(data);
  }

  return (
    <div className={isHidden=='true' ? styles.hidden : isNew ? styles.isNew : styles.none}>
      <div 
        className={styles.deckCard} 
        style={{
          '--backgroundUrl': `url(${process.env.PUBLIC_URL}/images/backgrounds/${cardDataAccessor.backgroundImage(data)})`,
          '--backgroundUrlHover': `url(${process.env.PUBLIC_URL}/images/backgrounds/${cardDataAccessor.backgroundImageHover(data)})`
        } as React.CSSProperties}
        
        onClick={handleClick}>
          <h2>{cardDataAccessor.title(data)}</h2>
          <div className={styles.content}>
              {cardDataAccessor.content(data)}
          </div>
          {/* only add this div if handleDeleteConfirmation != null */}
          {handleDeleteConfirmation &&
          <div className={styles.deleteIcon}>
            <span onClick={handleDelete}></span>
          </div>
          }
      </div>
      <Modal
          isOpen={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onConfirm={() => {
            setIsModalOpen(false);
            deleteConfirmed();
          }}
          heading={modalHeading}
          message={modalMessage}
      ></Modal>      
    </div>
  );
}

export default Card;

