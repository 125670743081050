import { BaseFirestoreDoc, IBaseClientData, schema } from "@lenfromkits/firestore-base-lib";
import { CollectionType } from "./types";
import { IProfileSummary } from "./profile.model";

export class Client extends BaseFirestoreDoc {
    data: IClientData;
}

export interface IClientData extends IBaseClientData{
    firstName: string;
    lastName: string;
    email: string;
    currentProfileId: string;
    profiles: IProfileSummary[];
}

schema.Doc(Client, { 
    type: CollectionType.Clients,
    parentType: null,
    summaries: [
        {
            fieldName: 'profiles',
            childType: CollectionType.Profiles,
            maps:[
                {fieldName: 'name'}
            ]
        }
    ]
});


