import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './main.menu.module.css';

export interface HamburgerMenuProps{
    className?: any;
    menuItems: HamburgerMenuItem[];
}

export interface HamburgerMenuItem{
  text:string 
  url:string
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({className, menuItems}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navLinksRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !navLinksRef.current?.contains(event.target as Node) && (event.target as any).id !== 'hamburgerButton') {
      setTimeout(() => setIsOpen(false), 1); // Delay closing the menu since interferes with the NavLink click
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }; 
  }, []);

  return (
    <div className={className}>
        <div className={styles.hamburgerMenu} ref={menuRef}>
          <button id="hamburgerButton" className={styles.hamburgerIcon} onClick={() => setIsOpen(!isOpen)}>
              &#9776; {/* Hamburger icon */}
          </button>
        </div>
        {isOpen && (
            <div className={styles.dropdownMenu} ref={navLinksRef}>
              {
                  menuItems.map((item, index) => (
                      <NavLink key={index} to={item.url} className={styles.menuItem} onClick={() => setIsOpen(false)}>
                          {item.text}
                      </NavLink>
                  ))
              }
            </div>
        )}
    </div>
  );
};

export default HamburgerMenu;