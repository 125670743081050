import React, { useContext } from 'react';
import styles from './modal-error.component.module.css';
import { on } from 'events';
import { useMainContext } from '../../contextProviders/main.context';

interface ModalErrorProps {

}

const ModalError: React.FC<ModalErrorProps> = () => {
  const {errorMessage, setErrorMessage} = useMainContext();
  let isOpen = errorMessage !== null && errorMessage !== '';

    const onConfirmLocal = (e:React.MouseEvent) => {
        e.stopPropagation();
        setErrorMessage(null);
        isOpen = false;
    };
 
    return ( isOpen &&
        <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
            <h2>An error has occurred</h2>
            <p>{(errorMessage as any).message ? (errorMessage as Error).message + '\r\n<br/>' + (errorMessage as Error).stack?.replace('\n', '\n<br/>') : errorMessage}</p>
            <button onClick={onConfirmLocal}>Got It</button>
        </div>
        </div>
    );
};

export default ModalError;