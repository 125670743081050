import React, { useEffect, useState } from 'react';
import styles from './login.module.css'; 
import Globals from '../../services/globals.instances';
import { useShowSpinner } from '../../services/commonFunctions';
import { useMainContext } from '../../contextProviders/main.context';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const showSpinner = useShowSpinner();
  const {setErrorMessage} = useMainContext();

  const handleLogin = (event: React.FormEvent) => {
    try{
      event.preventDefault(); 
      showSpinner();
      Globals.firebaseService.Login(username, password);

    }catch(e){
      setErrorMessage(e);
    }

  };

  const handleGoogleLogin = () => {
    try{
    //this will cause the page to reload and then we'll see below in the useEffect if it's logged in.
    //Google also has a popup option but it was blocked on iphone so fuck it.
    showSpinner();
      Globals.firebaseService.LoginGoogle();
    }catch(e){
      setErrorMessage(e);
    }
  };

  // Handle the redirect result when component mounts
  // useEffect(() => {
  //   async function handleRedirectResult() {
  //     console.log('in use effect for google login result');
      
  //     Globals.firebaseService.auth.LoginGoogleResult();
  //   }
  //   handleRedirectResult();
  // }, []);  
  
  return (
    <div className={styles.loginContainer}>
      <form onSubmit={handleLogin} className={styles.loginForm}>
        <div className={styles.formGroup}>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required 
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>Login</button>
      </form>
      <button onClick={handleGoogleLogin} className={styles.googleButton}>
        Login with Google
      </button>
    </div>
  );
};

export default Login;