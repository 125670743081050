import React, { createContext, useState, useContext, useEffect } from 'react';
import Globals from '../services/globals.instances';


interface MainContextProviderProps {
    children: React.ReactNode;
}

interface MainContextType {
    isLoggedIn: string;
    setIsLoggedIn: (value: string) => void;
    errorMessage: any;
    setErrorMessage: (value: string) => void;
}

// Create the context
const MainContext = createContext<MainContextType>({
  isLoggedIn: 'false',
  setIsLoggedIn: (value: string) => {},
  errorMessage: null,
  setErrorMessage: (value: string) => {},

});


const MainContextProvider:React.FC<MainContextProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState("isLoggedIn");
  const [errorMessage, setErrorMessage] = useState(null);

  const userLoggedIn = () => {
    setIsLoggedIn('true');
  }

  useEffect(() => {
    Globals.firebaseService.emitter.on('userLoggedIn', userLoggedIn, 'MainContextProvider');

    return () => {
      Globals.firebaseService.emitter.off('userLoggedIn', 'MainContextProvider');
    };

  }, []);
  

  return (
    <MainContext.Provider value={{
       isLoggedIn, setIsLoggedIn,
      errorMessage, setErrorMessage
       }}>
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error("main context provider must exist");
  }
  return context;
};




export default MainContextProvider;
//export { MainContext };


