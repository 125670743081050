import React, {useContext, useEffect} from "react";
import styles from "./main.page.module.css";
import FeatureCard from "./feature.card";
import Moto from "../../components/moto/moto.component";
import LoginPage from "./login";
//import {MainContext} from "../../contextProviders/main.context";
import HamburgerMenu from "../../components/menu/main.menu";
import { useHideSpinner } from "../../services/commonFunctions";
import { useMainContext } from "../../contextProviders/main.context";

const MainPage: React.FC = () => {

  const {isLoggedIn, setIsLoggedIn} = useMainContext();

  const hideSpinner = useHideSpinner();

  useEffect(() => {
    let a = isLoggedIn;
    if (a == 'true') {
      hideSpinner();
    }
    
  }, [isLoggedIn]);

  return (
    <div className={styles.main}>

      <header className={styles.appHeader}>
        <div className={styles.headerInner}>
          <h1>Examinarium</h1>
          <HamburgerMenu 
            className={styles.hamburger} 
            menuItems={[
              {text: 'Sign Out', url: '/?signout=true'},
            ]}
          />
          
          
          <div className={styles.motoWrapper}>
            <Moto />
          </div>
        </div>
      </header>

      {isLoggedIn != 'true' ? (
      <div> 
        <LoginPage />
      </div>
      ) : (
      <div className={styles.cardContainer}>        
        <FeatureCard title="Create Questions and Answers" background="1" url="/decks?type=design">
          <p>
            This is prime space! Use it to elaborate on your attention-grabbing
            section title. Explain what this section is about, share some
            details, and give just the right amount of information to get the
            audience hooked.
          </p>
        </FeatureCard>
        <FeatureCard title="Quiz Yourself" background="2" url="/decks?type=quiz">
          <p>
            This is prime space! Use it to elaborate on your attention-grabbing
            section title. Explain what this section is about, share some
            details, and give just the right amount of information to get the
            audience hooked.
          </p>
        </FeatureCard>
        <FeatureCard title="Purchase a Membership or Features" background="3" url="/purchase">
          <p>
            Purchase a membership for mobile phone access, or to receive
            interactive messages by text or email, or to use AI to help create
            your questions and answers. Man.
          </p>
        </FeatureCard>
      </div>
    )}



    </div>
  );
}

export default MainPage;