import React, {useEffect, useReducer, useRef, useState} from "react";
import styles from "./deck.design.page.module.css";
import Globals from "../../services/globals.instances";
import {Card, CollectionType, Deck, ICardSummary, IDeckSummary, Profile} from "@lenfromkits/examinarium-common";
import CardView from "../../components/cardView/card.view";
import Header from "../../components/pageHeader/header.component";
import { CardDataAccessor } from "../../components/cardView/card";
import { DataBindingContext, eUpdateState } from "@lenfromkits/firestore-base-lib";
import { useMainContext } from "../../contextProviders/main.context";


const DeckDesignPage: React.FC = () => {
  
  const [deck, setDeck] = useState<Deck>(null);
  const {setErrorMessage} = useMainContext();
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  //get the query param "parentId"
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id') as any;  

  useEffect(() => {
    try{

      let ctx = DataBindingContext(forceUpdate, (data) => {
        }
      );
  
      const fetchCards = async () => {
          let deck = await Globals.firebaseService.GetDocById<Deck>(CollectionType.Decks, id);
          setDeck(deck);
          ctx.BindData(deck, true);
      }; 
      fetchCards();

      return () => {
        ctx.UnbindData();
      }


    }catch(e){
      setErrorMessage(e);
    }

  },[]);


  try{

    //define the tool to pass to the card view so it knows how to read fields dynamically from the data
    let cardDataAccessor = new CardDataAccessor<Card>({
      id:data=>data.data.id,
      title:data=>data.data.name,
      url:data=>'/carddesign',
      backgroundImage:data=>'card.webp',
      backgroundImageHover:data=>'cardHover.webp',
      content:data=>data.description//description is a readonly field on the card with logic for card types
    });
    

    let createNewCardCallback = async ():Promise<void>=>{

      //default all new cards to word/answer types. When they edit the card they can specify the type.
      let card = Globals.firebaseService.CreateNew<Card>(CollectionType.WordAnswerCards, id);
      card.data.name = 'New Card ' + (deck.data.cards.length + 1);
      await card.Update();
      return;
    }

    let handleDelete = async (data:ICardSummary):Promise<void>=>{
      let card = await Globals.firebaseService.GetDocById<Card>(CollectionType.Cards, data.id);
      card.updateState = eUpdateState.Delete;
      await card.Update();
      return;
    };

    let test = ()=>{
      setErrorMessage('Test Error Message');
    }

    return (  
      <div className={styles.main}>
        <Header
          pageTitle='DESIGN CARDS'
          menuItems={[ 
              {text:'Home', url:'/main'},
              {text:'Decks', url:'/decks?type=design'},
              {text:'Log Out', url:'/?signout=true'},
          ]}
        />
  
        <div>
          <CardView 
            pageId='deckDesign'
            data={deck?.data.cards}
            cardDataAccessor={cardDataAccessor}
            createCardCallback={createNewCardCallback}
            handleDelete={handleDelete}
          />
        </div>

      </div>
    );

  }catch(e){
    setErrorMessage(e);
  }

}

export default DeckDesignPage;

