import { Card, ICardData } from "./card.model";


export class MultipleChoiceCard extends Card {
    override data: IMultipleChoiceCardData;
    constructor(){
        super();
    }
}

export interface IMultipleChoiceCardData extends ICardData{
    options: string[];
    answerIndex: number;
}