import { GetText } from "../languages";
import { Card, ICardData } from "./card.model";



export class DiagramCard extends Card {
    override data: IDiagramCardData

    constructor(){
        super();
        this.data.question = GetText("diagramQuestion");
    }
}

export interface IDiagramCardData extends ICardData{
    question: string;
}
