import React, { useState, useMemo, useEffect, useContext } from 'react';
import styles from './card.view.module.css'; // Import the CSS file
import Card, { CardDataAccessor } from './card'; // Assuming these are already created
import ProfileSelector from '../profileSelector/profile-selector.component';
import { useAsyncWithSpinner } from '../../services/commonFunctions';
import { useMainContext } from '../../contextProviders/main.context';

interface CardViewProps {
  data: any[];
  cardDataAccessor: CardDataAccessor<any>;
  showProfileSelector?: boolean;
  createCardCallback?: () => Promise<void>;
  handleDelete?: (data:any) => Promise<void>;

  /**
   * used to identify the page in local storage for current page.
   */
  pageId: string;
}

/**
 * The createCardCallback is expected to create a new item and have it end up in the data array that's passed in.
 * Usually, that means the data array passed here is just the 'summary' list on the parent and then
 * createCardCallback creates a new document and updates it, while the firestore client lib automatically adds a new 
 * summary item to the summary list on the parent - which ought to be the same list that's passed in here.
 * @param param0 
 * @returns 
 */
const CardView: React.FC<CardViewProps> = ({ data, cardDataAccessor, pageId, showProfileSelector, createCardCallback, handleDelete }) => {
  const spinnerAsync = useAsyncWithSpinner();
  const {setErrorMessage} = useMainContext();

  const [filterText, setFilterText] = useState<string>(()=>{
    try{
      const savedFilter = localStorage.getItem('filterText' + pageId);
      return savedFilter || '';
    }catch(e){
      setErrorMessage(e);
    }
  });
  
  const [sortAsc, setSortAsc] = useState<boolean>(()=>{
    try{
      const savedSort = localStorage.getItem('sortAsc' + pageId);
      return savedSort ? savedSort === 'true' : true;
    }catch(e){
      setErrorMessage(e);
    }
  });

  const [showFilters, setShowFilters] = useState<boolean>(()=>{
    try{
      const savedShowFilters = localStorage.getItem('showFilters' + pageId);
      return savedShowFilters ? savedShowFilters === 'true' : false;
    }catch(e){
      setErrorMessage(e);
    }
  });

  data ||= [];
  const [dataItems, setDataItems] = useState<any[]>([...data]);
  const [newItemsToKeepFirstInList, setNewItemsToKeepFirstInList] = useState<any[]>([]);

  const [itemsPerPage, setItemsPerPage] = useState(()=>{
    try{
      const savedItemsPerPage = localStorage.getItem('itemsPerPage' + pageId);
      return savedItemsPerPage ? parseInt(savedItemsPerPage, 10) : 8;
    }catch(e){
      setErrorMessage(e);
    }
  });

  //set the page to the last page viewed if it was saved in local storage
  const [currentPage, setCurrentPage] = useState<number>(()=>{
    try{
      const savedPage = localStorage.getItem('currentPage' + pageId );
      return savedPage ? parseInt(savedPage, 10) : 1;
    }catch(e){
      setErrorMessage(e);
    }
  });

  const filteredAndSortedCards = useMemo(() => {
    try{
      const filteredCards = data.filter(dataItem =>
        cardDataAccessor.title(dataItem)?.toLowerCase().includes(filterText.toLowerCase()) ||
        cardDataAccessor.content(dataItem)?.toLowerCase().includes(filterText.toLowerCase())
      );

      let sortedCards =  filteredCards.sort((a, b) => {
        const titleA = cardDataAccessor.title(a)?.toLowerCase();
        const titleB = cardDataAccessor.title(b)?.toLowerCase();
        if (titleA < titleB) return sortAsc ? -1 : 1; 
        if (titleA > titleB) return sortAsc ? 1 : -1;
        return 0;
      });

      //but, any new ones must appear first for now and must be on first page.
      //remove any new ones that are in the newItemsFirst list from the list and insert them at the start
      sortedCards = sortedCards.filter(card => !newItemsToKeepFirstInList.includes(card));
      sortedCards = [...newItemsToKeepFirstInList, ...sortedCards];

      return sortedCards;
    }
    catch(e){
      setErrorMessage(e);
    }
  }, [data, dataItems, filterText, sortAsc, newItemsToKeepFirstInList]);

  // Total items and pages calculation
  const totalItems = filteredAndSortedCards.length;
  const totalPages = Math.ceil(totalItems / (itemsPerPage || 1)) || 1;

  // Current cards to display based on pagination
  const currentCards = useMemo(() => {
    try{
      const startIndex = (currentPage - 1) * itemsPerPage;
      return filteredAndSortedCards.slice(startIndex, startIndex + itemsPerPage);
    }catch(e){
      setErrorMessage(e);
    }
  }, [filteredAndSortedCards, currentPage, itemsPerPage]);

  // Adjust current page if total pages change
  useEffect(() => {
    try{
      const newTotalPages = Math.ceil(filteredAndSortedCards.length / itemsPerPage) || 1;
      if (currentPage > newTotalPages && data.length > 0) {// first times in here have no data so don't set page to 1
        setCurrentPage(newTotalPages);
      }
    }catch(e){
      setErrorMessage(e);
    }
  }, [filteredAndSortedCards, itemsPerPage]);

  useEffect(() => {
    try{
      localStorage.setItem('itemsPerPage' + pageId, itemsPerPage.toString());
    }catch(e){
      setErrorMessage(e);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    try{
      localStorage.setItem('currentPage' + pageId, currentPage.toString());
    }catch(e){
      setErrorMessage(e);
    }
  }, [currentPage]);

  useEffect(() => {
    try{
      localStorage.setItem('filterText' + pageId, filterText);
    }catch(e){
      setErrorMessage(e);
    }
  }, [filterText]);

  useEffect(() => {
    try{
      localStorage.setItem('sortAsc' + pageId, sortAsc.toString());
    }catch(e){
      setErrorMessage(e);
    }
  }, [sortAsc]);

  useEffect(() => {
    try{
      localStorage.setItem('showFilters' + pageId, showFilters.toString());
    }catch(e){
      setErrorMessage(e);
    }
  }, [showFilters]);

  // Reset to first page when filter text changes
  useEffect(() => {
    if (filterText != ''){
      setCurrentPage(1);
    }
  }, [filterText]);



  const handleDeleteCard = async (dataItem):Promise<void> => {
    try{
      await spinnerAsync(async () => {
        await handleDelete(dataItem);
      }); 

      setDataItems([...data]); // Update data items
      setNewItemsToKeepFirstInList(newItemsToKeepFirstInList.filter(item => item !== dataItem));
    }catch(e){
      setErrorMessage(e);
    }
  }

  const handleAddCard = async () => {
    try{
      let listBefore = [...data];

      await spinnerAsync(createCardCallback); // Async in case callback is asynchronous

      //find the new item in the list based on what was there before
      let newItems = data.filter(item => !listBefore.includes(item));
      //and add it as a 'new' item so it appears at the top of the list for now.
      setNewItemsToKeepFirstInList([...newItems, ...newItemsToKeepFirstInList]);

      setDataItems([...data]); // Update data items
      setCurrentPage(1); // Go to first page
    }catch(e){
      setErrorMessage(e);
    }
  };

  try{
    return (
      <div className={styles.cardViewContainer}>
        {/* Toolbar */}
        <div className={styles.toolbar}>
          <div className={styles.leftToolbar}>
            {/* Items per page selector */}
            <label className={styles.itemsPerPageLabel}>
              Items per page:
              <select
                className={styles.itemsPerPageSelect}
                value={itemsPerPage}
                onChange={(e) => { setItemsPerPage(Number(e.target.value)); setCurrentPage(1); }}
              >
                <option value={4}>4</option>
                <option value={8}>8</option>
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={48}>48</option>
              </select>
            </label>

            {/* Filter Icon */}
            <span 
              className={filterText != '' ? styles.filterIconSelected : styles.filterIcon} 
              onClick={() => setShowFilters(!showFilters)} 
            />
            {/* Add Icon */}
            {createCardCallback && (
              <span 
                className={styles.addIcon} 
                onClick={() => handleAddCard()} 
              />        
            )}
          </div>

          <div className={styles.rightToolbar}>
            <ProfileSelector className={styles.profileSelector}/>
          </div>
        </div>

        {/* Filter and Sort Inputs with transition */}
        <div className={`${styles.filterSection} ${showFilters ? styles.show : ''}`}>
          <input
            type="text"
            className={styles.filterInput}
            placeholder="Filter cards..."
            value={filterText}
            onChange={(e) => {setFilterText(e.target.value);setNewItemsToKeepFirstInList([])}}
          />
          <button className={styles.sortButton} onClick={() => setSortAsc(!sortAsc)}>
            {sortAsc ? 'Sort Descending' : 'Sort Ascending'}
          </button>
        </div>

        {/* Card Grid */}
        <div className={styles.cardGrid}>
          {currentCards.map(dataItem => (
            <Card
            key={cardDataAccessor.id(dataItem)} 
            cardDataAccessor={cardDataAccessor} 
            data={dataItem} 
            isNew={newItemsToKeepFirstInList.includes(dataItem)}
              handleDeleteConfirmation={handleDeleteCard}
            />
          ))}

          {/* Add placeholders if on the last page and more than one page */}
          {currentPage === totalPages && totalPages > 1 && (() => {
            const placeholdersNeeded = itemsPerPage - currentCards.length;
            return Array.from({ length: placeholdersNeeded }).map((_, index) => (
              <Card isHidden="true" key={index} cardDataAccessor={cardDataAccessor} data={currentCards[0]} />
            ));
          })()}

          
        </div>

        {/* Pagination Controls */}
        <div className={styles.pagination}>
          <button className={styles.paginationButton} onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>First</button>
          <button className={styles.paginationButton} onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Previous</button>
          <span className={styles.paginationInfo}>Page {currentPage} of {totalPages}</span>
          <label className={styles.paginationLabel}>
            Go to page:
            <input
              type="number"
              className={styles.paginationInput}
              min="1"
              max={totalPages}
              value={currentPage}
              onChange={(e) => {
                let page = Number(e.target.value);
                if (isNaN(page)) page = 1;
                page = Math.max(1, Math.min(totalPages, page));
                setCurrentPage(page);
                setNewItemsToKeepFirstInList([]);
              }}
            />
          </label>
          <button className={styles.paginationButton} onClick={() => {setCurrentPage(prev => Math.min(prev + 1, totalPages));setNewItemsToKeepFirstInList([]) }} disabled={currentPage === totalPages}>Next</button>
          <button className={styles.paginationButton} onClick={() => {setCurrentPage(totalPages);setNewItemsToKeepFirstInList([])}} disabled={currentPage === totalPages}>Last</button>
          <span className={styles.totalItems}>Total items: {totalItems}</span>
        </div>
      </div>
    );
  }catch(e){
    setErrorMessage(e);
  }
};

export default CardView;
