import * as clientLib from '@lenfromkits/firestore-client-lib';
import * as models from '@lenfromkits/examinarium-common';
import * as baseLib from '@lenfromkits/firestore-base-lib';

export type Events={
    userLoggedIn:{client:models.Client};
}

export class FirestoreService {
    private _firestore:baseLib.IDataAccess;
    public readonly emitter = baseLib.mitt<Events>();
    private _auth:clientLib.AuthService;

    constructor() {
        // Your web app's Firebase configuration
        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
        const firebaseConfig:clientLib.FirestoreOptions = {
            apiKey: "AIzaSyCr6AsexAfi2iWpkTB2A6oXzhBxH0rlWRo",
            authDomain: "examinarium-66643.firebaseapp.com",
            projectId: "examinarium-66643",
            storageBucket: "examinarium-66643.appspot.com",
            messagingSenderId: "359607804481",
            appId: "1:359607804481:web:ea789b30ae932711d1dcc0", 
            measurementId: "G-4CEY19KMKL",
        };

        //look at the current domain name used to determine if we are running locally or not
        const currentDomain = window.location.hostname;
        const isLocal = currentDomain === 'localhost' || currentDomain === '127.0.0.1';

        const firebaseClientConfig:clientLib.InitOptions = {
            //runLocally: isLocal, 
           runLocally: false,//todo: ditch. only need this if running on local against prod function server
        }; 
 
        const options:clientLib.AuthInitOptions = {
            localAuthPort: firebaseClientConfig.localAuthPort,
            runLocally: firebaseClientConfig.runLocally
        };

        //must call this first to init firebase
        clientLib.FirestoreClientService.InitFirebase(firebaseConfig);
        this._auth = new clientLib.AuthService(options);

        //once they log in, then we can create the firestore client since it needs the token
        //So the firebase stuff doesn't run unless the user signs in at some point.
        this._auth.emitter.on('userLoggedIn', async (e)=>{
            this._auth.emitter.off('userLoggedIn', this);

            //create the firestore client tool
            this._firestore = new clientLib.FirestoreClientService(firebaseClientConfig, models.schema.definitions, this._auth.accessToken, models.CollectionType.Clients);

            //now firestore has some stuff to do like load the client.  wait for it (ie, since the constructor couldn't be async)
            this._firestore.emitter.on('userLoggedIn', (e)=>{
                this._firestore.emitter.off('userLoggedIn', this);//pass 'this' as a key for the 'off' to find this anon function
                //when the user logs on, re-emit this event
                this.emitter.emit('userLoggedIn', {client:e.client as any as models.Client});
            }, this);

        }, this);
    }
 
    public Logout():void {
        this._auth.LogOut(); 
    }

    public CreateNew<T extends baseLib.BaseFirestoreDoc>(type:baseLib.CollectionType, parentId:string):T{
        let doc = baseLib.BaseFirestoreDoc.CreateNew(type, parentId, this._firestore);
        return doc as T;
    }

    public get client():models.Client {
        return this._firestore.client as any as models.Client;
    }

    public async LoginGoogle():Promise<void> {
        return this._auth.LoginGoogle();
    } 

    public async Login(username:string, password:string):Promise<void> {
        return this._auth.LoginEmail(username, password);
    }
 
    public async GetAllDocs<T extends baseLib.BaseFirestoreDoc>(type:baseLib.CollectionType):Promise<T[]> {
        let data = await this._firestore.GetAllDocs(type);
        return data as any as T[];
    }

    public async GetDocs<T extends baseLib.BaseFirestoreDoc>(type:baseLib.CollectionType, filters:baseLib.QueryFilter[]):Promise<T[]>;
    public async GetDocs<T extends baseLib.BaseFirestoreDoc>(type:baseLib.CollectionType, filters:object):Promise<T[]>;
    public async GetDocs<T extends baseLib.BaseFirestoreDoc>(type:baseLib.CollectionType, filters:any):Promise<T[]> {

        if (Array.isArray(filters)){
            let data = await this._firestore.GetDocs(type, filters);
            return data as any as T[];
        }
        else{
            //the filters here are the keys and values on the object and everything is an '=='
            let keys = Object.keys(filters);
            let queryFilters = keys.map(key=>{return {fieldPath:key, opStr:'==', value:filters[key]} as baseLib.QueryFilter});
            let data = await this._firestore.GetDocs(type, queryFilters);
            return data as any as T[];
        }
    }

    public async GetDocById<T extends baseLib.BaseFirestoreDoc>(type:baseLib.CollectionType, id:string):Promise<T> {
        let data = await this._firestore.GetDocById(type, id) as T;
        return data;
    }

    /**
     * Create, Update or Delete a document based on the updateState of the doc.
     * @param doc 
     * @returns 
     */
    public async UpdateDoc(doc:baseLib.BaseFirestoreDoc):Promise<void> {
        try{
            return this._firestore.UpdateDoc(doc);
        }
        catch(e){
            console.error(e);
            debugger;
        }
    }
} 