import { BaseFirestoreDoc, schema, IDocumentData, ISummaryData } from "@lenfromkits/firestore-base-lib";
import { CollectionType } from "./types";
import { ICardSummary } from "./cards/card.model";

export class Deck extends BaseFirestoreDoc {
    override data: IDeckData; 

}

export interface IDeckData extends IDocumentData {
    name: string;
    description: string;
    cards: ICardSummary[];
}

export interface IDeckSummary extends ISummaryData{
    name: string;
    description: string;    
}

schema.Doc(Deck, {
    type: CollectionType.Decks,
    parentType: CollectionType.Profiles,
    summaries: [
        {
            fieldName: 'cards',
            childType: CollectionType.Cards,
            maps:[
                {fieldName: 'name'},
                {fieldName: 'cardType'}
            ]
        }
    ],
    childFields: [
        {
            fieldName: 'options',//will never be null.
            keyFieldName:null//lets it know it's an array, but not objects
        }
    ]

});


