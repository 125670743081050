import React, { useEffect, useReducer, useState } from "react";
import styles from './profile-selector.component.module.css'
import { IProfileSummary, IDeckSummary, Client } from "@lenfromkits/examinarium-common";
import Globals from '../../services/globals.instances';
import { DataBindingContext } from "@lenfromkits/firestore-base-lib";

export interface ProfileSelectorProps{
    className?:any;
}

const ProfileSelector:React.FC<ProfileSelectorProps> = (props)=>{
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [profileSummaries, setProfileSummaries] = useState(null);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [client, setClient] = useState<Client>(null);

    //watch for any changes to the profile data (well, it'll watch on the whole client)
    let ctx = DataBindingContext(forceUpdate)

    useEffect(()=>{
        let fetchData = async ()=>{
            try{
                let cl = await Globals.firebaseService.client;
                if (cl == null){
                    debugger;
                }
                setClient(cl);
                ctx.BindData(cl)
                setProfileSummaries(cl.data.profiles);
                setSelectedProfileId(cl.data.currentProfileId);
            }catch(e){
                //debugger;
                throw e; 
            }
        }
        fetchData();

        return ()=>{
            ctx.UnbindData();
        }

    },[]); 

    const handleProfileChange = (event) => {
        client.data.currentProfileId = event.target.value;
        setSelectedProfileId(event.target.value);
        Globals.firebaseService.UpdateDoc(client);
    }    

    return (
        <span className={props.className}>
            <span className={styles.main}>
                Profile: 
                <select value={selectedProfileId} onChange={handleProfileChange}>
                {profileSummaries ?
                    profileSummaries.map(profile => (
                    <option key={profile.id} value={profile.id}>
                        {profile.name}
                    </option>
                    ))
                : 
                    <option>Loading...</option>
                }
                </select>
            </span>
        </span>
    )
}

export default ProfileSelector;