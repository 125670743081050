import React, {useContext, useEffect} from "react";
import styles from "./external.main.page.module.css";
import Moto from "../../components/moto/moto.component";
import HamburgerMenu from "../../components/menu/main.menu";
import Globals from "../../services/globals.instances";
import { useMainContext } from "../../contextProviders/main.context";

const ExternalMainPage: React.FC = () => {

  const {isLoggedIn, setIsLoggedIn} = useMainContext();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const signout = urlParams.get('signout');
    if (signout === 'true') {
      Globals.firebaseService.Logout();//async
      setIsLoggedIn('false');
    }
  }, []);

  return (
    <div className={styles.main}>

      <header className={styles.appHeader}> 
        <div className={styles.headerInner}>
          <h1>Examinarium</h1>
          <HamburgerMenu 
            className={styles.hamburger} 
            menuItems={[
              {text: 'Sign In', url: '/main'},
              {text: 'Register', url: '/register'}
            ]}
          />
          
          <div className={styles.motoWrapper}>
            <Moto />
          </div>
        </div>
      </header>

    </div>
  );
}

export default ExternalMainPage;