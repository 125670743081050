// Thanks gpt
import React, { createContext, useContext, useState } from "react";

interface SpinnerContextType {
  isLoading: boolean;
  showSpinner: () => void;
  hideSpinner: () => void;
}

const SpinnerContext = createContext<SpinnerContextType | undefined>(undefined);

export const SpinnerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loadingCount, setLoadingCount] = useState(0);

  const showSpinner = () => {
    //setLoadingCount(prev => prev + 1);
    //return;
    //delay the show spinner so it doesn't constantly flash on the screen for a split second
    setTimeout(() => {
      try{
        setLoadingCount(prev => prev + 1);
      }catch(e){
        console.error(e);
      }
    }, 400);
  };
  const hideSpinner = () => {
    //let the count go into the negatives since this hide will often get called before the show,
    //because we have a timer on it. If so, the show will just bring it back to 0 and not show
    setLoadingCount(prev => prev - 1)
  };

  return (
    <SpinnerContext.Provider value={{ isLoading: loadingCount > 0, showSpinner, hideSpinner }}>
      {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = () => {
  const context = useContext(SpinnerContext);
  if (!context) {
    throw new Error("useSpinner must be used within a SpinnerProvider");
  }
  return context;
};






