// thanks gpt
import React from "react";
import { useSpinner } from "../../contextProviders/spinner.context";
import styles from "./spinner-overlay.component.module.css";

export const SpinnerOverlay: React.FC = () => {
  const { isLoading } = useSpinner();

  if (!isLoading) return null;

  return (
    <div className={styles.outerDiv}>
      <div className={styles.innerDiv}>
        <div className={styles.icon}></div> 
        <div className={styles.text}>Working on it...</div>
        
      </div>
    </div>
  );
};
