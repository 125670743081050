import React, {useEffect, useReducer, useRef, useState} from "react";
import styles from "./decks.page.module.css";
import Globals from "../../services/globals.instances";
import {CollectionType, Deck, IDeckSummary, Profile} from "@lenfromkits/examinarium-common";
import CardView from "../../components/cardView/card.view";
import Header from "../../components/pageHeader/header.component";
import { CardDataAccessor } from "../../components/cardView/card";
import { DataBindingContext, eUpdateState } from "@lenfromkits/firestore-base-lib";
import { useMainContext } from "../../contextProviders/main.context";


const DecksPage: React.FC = () => {
  
  const [profile, setProfile] = useState<Profile>(null);
  const {setErrorMessage} = useMainContext();


  //get the query param "type"
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type') as any;
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    try{

      let ctx = DataBindingContext(forceUpdate, (data) => {
          console.log('Data Change Called');
        }
      );
  
      const fetchDecks = async () => {
        try{
          let profile = await Globals.firebaseService.GetDocById<Profile>(CollectionType.Profiles, Globals.firebaseService.client.data.currentProfileId);
          setProfile(profile);
          console.log('Data fetched: ', profile);
          ctx.BindData(profile, true);
        }catch(e){
          setErrorMessage(e);
        }
      }; 
      fetchDecks();

      return () => {
        console.log("Cleanup function called"); 
        ctx.UnbindData();
      }


    }catch(e){
      setErrorMessage(e);
    }

  },[]);


  try{

    //define the tool to pass to the card view so it knows how to read fields dynamically from the data
    let cardDataAccessor = new CardDataAccessor<IDeckSummary>({
      id:data=>data.id, 
      title:data=>data.name,
      url:data=>'/deckdesign',
      backgroundImage:data=>'deck.webp',
      backgroundImageHover:data=>'../brain.webp',
      content:data=>data.description
    });

    let createNewCardCallback = async ():Promise<void>=>{
      let deck = Globals.firebaseService.CreateNew<Deck>(CollectionType.Decks, Globals.firebaseService.client.data.currentProfileId);
      deck.data.name = 'New Deck ' + (profile.data.decks.length + 1);
      deck.data.description = 'New Deck Description';
      await Globals.firebaseService.UpdateDoc(deck);
      return;
    }

    let handleDelete = async (data:IDeckSummary):Promise<void>=>{
      let deck = await Globals.firebaseService.GetDocById<Deck>(CollectionType.Decks, data.id);
      deck.updateState = eUpdateState.Delete;
      await Globals.firebaseService.UpdateDoc(deck);
      return;
    };

    let test = ()=>{
      setErrorMessage('Test Error Message');
    }

    return (  
      <div className={styles.main}>
        <Header
          pageTitle='DECKS'
          menuItems={[ 
              {text:'Home', url:'/main'},
              {text:'Log Out', url:'/?signout=true'},
          ]}
        />
  
        <div>
          <CardView 
            pageId='decks'
            data={profile?.data.decks}
            cardDataAccessor={cardDataAccessor}
            createCardCallback={createNewCardCallback}
            handleDelete={handleDelete}
          />
        </div>
        <button onClick={test}>Test</button>



      </div>
    );

  }catch(e){
    setErrorMessage(e);
  }

}

export default DecksPage;

