import { BaseFirestoreDoc, GetDataProxy } from ".";
import { DataProxyCallback, ForceUpdateScreenCallback, IDataBindCallback, IDataProxyCallbackData, IDataProxyCallbackDataWithReturn, IDocument, IDocumentData, IProxyData } from "./interfaces";

/**
 * Simplifies binding to data changes. You could otherwise just call GetDataProxy to ensure your data has proxies (if not sure) and then
 * listen for changes using the __meta.emitter.on('dataChanged', (data)=>{}); or 'dataChangedIncludingChildren' 
 * events, and then call to update the component view in the event handler, and then also remove the listeners.
 * But, this does all that, as well has handle arrays of data, and adding to the BaseFirestoreDoc's data property, all in one.
 * This also could evolve to do more which you won't get if you just use the emitter directly, and if there are any
 * future breaking changes, then you'll likely avoid them by using this.
 */
export class DataBindContext{
    private _data;
    private _includeAllChildren:boolean;

    constructor(private forceScreenUpdateFunction:ForceUpdateScreenCallback, private callback:DataProxyCallback){
    }

    UnbindData():void{
        if (this._data == null){
            return;//for some reason the unbind is called many times
        }

        //see if data is an array:
        if (Array.isArray(this._data)){
            this._data.forEach(doc => {
                this.UnbindDataItem(doc, this._includeAllChildren);
            });
        }
        else{
            this.UnbindDataItem(this._data, this._includeAllChildren);
        }        

        this._data = null;
    }


    private UnbindDataItem(obj:BaseFirestoreDoc | object, includeAllChildren:boolean):void{

        //add a listener for changes to the data.
        if (includeAllChildren){
            this._data.__meta.emitter.off('dataChangedIncludingChildren', this);
        }
        else{
            this._data.__meta.emitter.off('dataChanged', this);
        }
    }


    /**
     * This is the handler for when listening to all children and cannot be intercepted and changed, so there's no return value available.
     * @param data 
     * @returns 
     */
    private DataChangedHandler(data:IDataProxyCallbackData):void{
        //every change to the data raises two events, one for just the node that changed, and one for the parent to listen to
        //for all changes to children. Our listener only needs one of them, depending on if they're listening to all children or not.
        //If listening to all children events, they cannot intercept the changes.
        if (!this._includeAllChildren){
            return;
        }
        
        if (this.callback){
            this.callback(data);
        }
        if (this.forceScreenUpdateFunction){
            this.forceScreenUpdateFunction();
        }
    }

    /**
     * This is the handler for when listening to just the node and can be intercepted and changed, so there's a return value available.
     * @param data 
     * @returns 
     */
    private DataChangedHandlerWithReturnValue(data:IDataProxyCallbackDataWithReturn):void{

        //see notes above about this.
        if (this._includeAllChildren){
            return;
        }

        //let them know this was an event that was specificly from the node they bound to, so they have 
        //the opportunity to intercept the return value.
        let dataToPass:IDataBindCallback = data;//switch interfaces. This one just has the extra field on it
        dataToPass.allowsReturnValueInterception = true;

        if (this.callback){
            this.callback(dataToPass);
        }
        if (this.forceScreenUpdateFunction){
            this.forceScreenUpdateFunction();
        } 
        
    }

    private BindDataItem(obj:BaseFirestoreDoc | object, includeAllChildren:boolean):void{

        if (obj == null){
            throw new Error('Data cannot be null or undefined in BindDataItem');
        }

        //see if data is a class type of BaseFirestoreDoc 
        this._data = obj;
        if ((obj as BaseFirestoreDoc).isBaseFirestoreDoc) { 
        
            this._data = (obj as BaseFirestoreDoc).data;
        }

        if (this._data['__testIfHasProxy'] == undefined || (this._data as any).__meta == undefined){
            throw new Error('The data object must have a proxy around and a __meta property. Use GetDataProxy to ensure it has one and then use that proxy to make changes to the data.');
        }

        //add a listener for changes to the data.
        if (includeAllChildren){
            this._data.__meta.emitter.on('dataChangedIncludingChildren', (data)=>this.DataChangedHandler(data), this);
        }
        else{
            this._data.__meta.emitter.on('dataChanged', (data)=>this.DataChangedHandlerWithReturnValue(data), this);
        }
    }


    BindData(data:BaseFirestoreDoc | BaseFirestoreDoc[] | object | object[], includeAllChildren?:boolean):void{
        if (this._data != null){
            console.log('Data already bound');
            return;
        }

        this._includeAllChildren = includeAllChildren;

        console.log('Doing a data bind'); 

        //this is where the data gets set for the return function above.
        this._data = data;

        //see if data is an array:
        if (Array.isArray(data)){
            data.forEach(doc => {
                this.BindDataItem(doc, false);
            });
        }
        else{
            this.BindDataItem(data, includeAllChildren);
        }
    }


}

/**
 * Get a context that will call the two functions passed in. In react, it's a useReducer function that will force the screen to update: 
 *   const [, forceUpdate] = useReducer(x => x + 1, 0);  
 * @param forceScreenUpdateFunction: Optional: In React, get this from: const [, forceUpdate] = useReducer(x => x + 1, 0); Or just any function with no params that will force the screen to update.
 * @param callback: Optional: If you want a callback that passes params that you can intercept the data change and change the result, cancel the change, or just see what field changed.
 * @returns 
 */
export function DataBindingContext(forceScreenUpdateFunction?:ForceUpdateScreenCallback, callback?:DataProxyCallback):DataBindContext{
    return new DataBindContext(forceScreenUpdateFunction, callback);
}
