import { IDocumentData as IDocumentData, eUpdateState, IDocument, IDataAccess } from "./interfaces";
import { Guid, CollectionType } from "./baseFirestoreObjects";
import * as common from "./commonFunctions";
import * as schema from "./schema";


/**
 * Represents a collection in firebase (ie, root of an entire document)
 */
export abstract class BaseFirestoreDoc implements IDocument {
  private _collectionType:CollectionType;
  existingOnServer?:boolean;//if it was loaded from the server, vs, is it otherwise a new record
  data:IDocumentData = {} as any;//can't easily make this a getter/setter since then all sub classes can't just use this simple definition to override with their own type
  updateState:eUpdateState = eUpdateState.Update;
  protected dataAccess:IDataAccess; 

  /**
   * returns true if the class inherits from BaseFirestoreDoc, otherwise probably returns undefined.
   */
  get isBaseFirestoreDoc():boolean{ 
    return true;
  }

  get collectionType():CollectionType{
    return this._collectionType;
  }

  /**
   * Create, Update or Delete based on the updateState
   */
  async Update():Promise<void>{
    return this.dataAccess.UpdateDoc(this);
  }
  
  /**
   * Keep private to encourage calling the firestore service to do it so
   * the dataAccess can be set appropriately.  Maybe the website's server implments this interface
   * so it can intercept all calls to the firestore service from model classes, or maybe it 
   * passes in an instance to the client-lib's firestore class that it's using.
   * @param collectionType 
   * @param dataAccess 
   */
  protected constructor(){
    this.data = {} as any;//default
  } 

  public static Instantiate(type:CollectionType, dataAccess:IDataAccess){

    if (schema.definitions[type] == null){
      throw new Error('The type ' + type + ' is not defined in the schema.');
    } 


    let classType = schema.definitions[type].classJavascriptType;
    let newObj:BaseFirestoreDoc = new classType(type);
    newObj.dataAccess = dataAccess;
    newObj._collectionType = type;
    return newObj;
  }

  public static CreateNew(type:CollectionType, parentId:string, dataAccess:IDataAccess):BaseFirestoreDoc{
    //client id will be set on the server always. We don't know it here.
    let classType = schema.definitions[type].classJavascriptType;
    let newObj:BaseFirestoreDoc = BaseFirestoreDoc.Instantiate(type, dataAccess);
    newObj.updateState = eUpdateState.Create

    let newData:IDocumentData = {
      id : common.GenerateGuid(),
      parentId : parentId,
      created : new Date(),
      modified : new Date(),
      clientId : null
    }

    //this will create all the defaults and ensure there are no nulls, etc, according to the schema
    newObj.SetData(newData);

    return newObj;
  }


  SetData(data:IDocumentData){
    if (this.data === data){
      console.log('Calling SetData but setting data to the same data thats already in the ' + this.collectionType + ' object.');
      return;
    }
    this.MergeNewDataWithExisting(data);
  }

  ToJson():string{
    //turn into json but use a filter to omit any fields named __meta on any nodes in the hierarchy.
    return JSON.stringify(this.data, (key, value)=>{
      if (key == '__meta'){
        return undefined;
      }
      return value;
    });
  }

  MergeNewDataWithExisting(obj:object){
    let docSchema = schema.definitions[this.collectionType];
    this.MergeNewDataWithExisting_Recursive(obj, this.data, docSchema.childFields);
  }
  
  private MergeNewDataWithExisting_Recursive(objSource:object, objDest:object, objSchema:schema.DocFieldsDefinition):void{

    try{

      //while in deserialization, any data-change notifications must be ignored, so don't set values on the data
      //through the Proxy if it exists.
      objSource = common.GetDataProxyTarget(objSource);
      objDest = common.GetDataProxyTarget(objDest);

      //create a list of properties that need to be put onto the destination object.
      //It's a merge of what's on the source and what's defined in the schema.

      //define a dictionary with a key of string and a value of {fromSource:boolean, fromSchema:boolean, value:any, schemaField:schema.IChildProperty}
      let listOfProperties:{[key: string]: { 
        value?:any, childArraySchema?:schema.IChildArray, childObjectSchema?:schema.IChildObject}} = {};

      for (let propName in objSource){
        if (propName != '__meta' && typeof objSource[propName] != 'function'){
          listOfProperties[propName] = {value:objSource[propName]};
        }
      }
      if (objSchema != null){//we could be recursing on the data which has no schema defined for it.
        for (let propSchema of objSchema){
          let childObjectSchema:schema.IChildObject;
          let childArraySchema:schema.IChildArray;
          //which type of child field is this? object or array? the keyFieldName is only on arrays, and ALWAYS on arrays
          if ((propSchema as schema.IChildArray).keyFieldName != undefined){
            childArraySchema = propSchema as schema.IChildArray;
          }
          else{
            childObjectSchema = propSchema as schema.IChildObject;
          }
          listOfProperties[propSchema.fieldName] = {childArraySchema, childObjectSchema};
        }
      }
  

      for (let propName in listOfProperties){//iterates the keys of the object
        let prop = listOfProperties[propName];
  
        //if it's a date, it'll be a fucking 'object' with sub fields.
        //this tests 'if' it's a date which is dealt with below if not an array
        let dateInfo = common.ConvertFuckingFirebaseTimestampsToDate(prop.value);
  
        //AN ARRAY = AND NEED TO RECURSE EACH ITEM IN IT.
        //or else, if it's a nested object, instantiate the object for it and call recursively on it.
        if (Array.isArray(prop.value) || prop.childArraySchema != null){
          
          objDest[propName] ||= [];//don't replace the dest array, but add if needed.
          prop.value ||= [];//ensure this as well since we'll try to loop through it below.


          if (prop.value.length > 0){
            let listDest:{}[] = common.GetDataProxyTarget(objDest[propName]) as {}[];
            let listDestCopy = [...listDest];
            listDest.length = 0;//clear, we'll repopulate it.
            let listSource:{}[] = common.GetDataProxyTarget(prop.value) as {}[];
            //if the there are items in the array,
            //and either the schema says these are non-object primitive types,
            //or the first item in the list is a non-object primitive type
            //then we need to just wipe them out and copy from them from the source since no need to worry about maintaining refs.
            //this also includes child 'objects' if the schema didn't specify some sort of key id field              
            if ((prop.childArraySchema != null && prop.childArraySchema?.keyFieldName == null) || typeof prop.value[0] != 'object'){
              for (let i = 0; i < listSource.length; i++){
                listDest.push(listSource[i]);
              }
              //these lists now match the source data perfectly.
            }
            else{
              //It is an array of objects. For each obj in the source, find the corresponding object in the dest and copy the data over to it.
              //This ONLY works if we have id fields on the objects in the array. If we don't, 
              //then we can't match them up and we'll just have to copy the whole array over, not maintaining any references, but that's done above when keyFieldName=null.
              for (let i = 0; i < listSource.length; i++){
                //See if we can find the corresponding child obj in the existing dest list
                let existingChildObj = listDestCopy.find(o=>o[prop.childArraySchema?.keyFieldName] == listSource[i][prop.childArraySchema?.keyFieldName]);
                if (existingChildObj == null){
                  //create an instance for it and set its unique id
                  existingChildObj = {};
                  existingChildObj[prop.childArraySchema?.keyFieldName] = listSource[i][prop.childArraySchema?.keyFieldName];
                }
                listDest.push(existingChildObj);

                //now we need to copy all the fields (and children) onto that new/existing instance, so RECURSE...
                //pass the schema for the child - if there is schema defined.
                this.MergeNewDataWithExisting_Recursive(listSource[i], existingChildObj, prop.childArraySchema?.childFields);
              }
            }
          }
        }
        //IS IT A BLOODY DATE
        else if (dateInfo.isDate){
          //any date coming from firebase needs some fixing.
          objDest[propName] = dateInfo.date;
        }
        //IS IT A NESTED OBJECT = AND NEED TO RECURSE ON IT...
        else if (typeof prop.value === 'object' || prop.childObjectSchema != null){
          //if source and dest are null object
          if (prop.value == null && objDest[propName] == null){
            if (!(prop.childObjectSchema?.allowNull)){
              //then it must have a value in the dest always
              objDest[propName] = {};
              prop.value = {};//do this also since we'll reference it later.
            }
          }
          //if the source is not null and the dest is null
          else if (prop.value != null && objDest[propName] == null){
            //set a new object for the dest. (could probably just set the dest to the source as well
            //but we'll still need to recurse to ensure nulls are set, etc. anyway)
            objDest[propName] = {};
          }
          //if the source is null and the dest is not null
          else if (prop.value == null && objDest[propName] != null){
            //the source is basically wiping out the dest object.
            //set it to null if allowed.
            if (prop.childObjectSchema?.allowNull){
              objDest[propName] = null;
            }
            else{
              objDest[propName] = {};
              prop.value = {};//do this also since we'll reference it later.
            }
          }
          //if the source and dest are both not null
          else{
            //the source needs to be merged with the dest. Do nothing here.
          }

          //now merge if there is a dest object.
          if (objDest[propName] != null){
            this.MergeNewDataWithExisting_Recursive(prop.value, objDest[propName], prop.childObjectSchema?.childFields);
          }
        }
        //JUST A FIELD TO BE COPIED...
        else{
          objDest[propName] = prop.value;
          //but if null, see if there's a default value. Should only set initially when undefined but not when 'null'
          if (prop.value == undefined && prop.childObjectSchema.defaultValue != undefined){
            objDest[propName] = prop.childObjectSchema.defaultValue;
          }
          if (prop.value == null && !(prop.childObjectSchema?.allowNull)){
            objDest[propName] = {};
          }
        }
      }

      //now make sure our dest object doesn't have any fields that aren't in the source object or in the schema.
      for (let i=0; i < Object.keys(objDest).length; i++){
        let propName = Object.keys(objDest)[i];
        if (listOfProperties[propName] == null && propName != '__meta'){
          delete objDest[propName];
        }
      }
  
    }
    catch(ex){
      debugger;
      throw ex;
    }
  }
}
