import React from 'react';
import './App.css';
import MainPage from '../main-page/main.page';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DecksPage from '../decks-page/decks.page';
import '../../assets/fonts/font-sizes.css'; // Import the CSS file is like including it in the html file.
import '../../assets/fonts/font-imports.css';
import '../../themes/dark-theme.css'; 
import '../../themes/light-theme.css'; 
import MainContextProvider from '../../contextProviders/main.context';
import ExternalMainPage from '../external-main/external.main.page';
import DeckDesignPage from '../deck-design/deck.design.page';
import { SpinnerProvider } from '../../contextProviders/spinner.context';
import { SpinnerOverlay } from '../../components/spinnerOverlay/spinner-overlay.component';
import ModalError from '../../components/modelError/modal-error.component';


function App() {
  return (
      <MainContextProvider>
        <SpinnerProvider>
          <div className="container">
            
            <div className="content">
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<ExternalMainPage/>} />
                  <Route path="/main" element={<MainPage/>} />
                  <Route path="/decks" element={<DecksPage/>} />
                  <Route path="/deckdesign" element={<DeckDesignPage/>} />
                </Routes>
              </BrowserRouter>
            </div>
            <div className="overlay"></div>
          </div>
        <SpinnerOverlay/>
        <ModalError/>
        </SpinnerProvider>
      </MainContextProvider>
  );
}

export default App;
