import { BaseFirestoreDoc, schema, IDocumentData } from "@lenfromkits/firestore-base-lib";
import { CollectionType } from "./types";
import { Deck, IDeckSummary } from "./deck.model";

export class Profile extends BaseFirestoreDoc {
    data: IProfileData;
}

export interface IProfileData extends IDocumentData{
    name: string;
    decks: IDeckSummary[];
}

export interface IProfileSummary extends IDocumentData{
    name: string;
}

schema.Doc(Profile, {
    type: CollectionType.Profiles,
    parentType: CollectionType.Clients,
    summaries: [
        {
            fieldName: 'decks',
            childType: CollectionType.Decks,
            maps:[
                {fieldName: 'name'},
                {fieldName: 'description'}
            ]
        }
    ]
});