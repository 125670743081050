
import { useSpinner } from "../contextProviders/spinner.context";

/**
 * Returns a function you can call and pass your async function to. It will show a spinner while the async function is running.
 * Thanks gpt.
 * @returns 
 */
export const useAsyncWithSpinner = () => {
  const { showSpinner, hideSpinner } = useSpinner();

  const asyncWrapper = async <T>(fn: () => Promise<T>): Promise<T> => {
    showSpinner();
    try {
      const result = await fn();
      return result;
    } finally {
      hideSpinner();
    }
  };

  return asyncWrapper;
};

export function useShowSpinner(){
    const { showSpinner, hideSpinner } = useSpinner();

    const func = () => {
      showSpinner();
    };
  
    return func;
}

export function useHideSpinner(){
    const { showSpinner, hideSpinner } = useSpinner();

    const func = () => {
      hideSpinner();
    };
  
    return func;
}

