import React from "react";
import styles from "./brain.component.module.css";

interface BrainComponentProps {
    width: string;
}

const Brain: React.FC<BrainComponentProps> = (props) => {
  return (
    <img 
    src="/images/brain.webp" alt="Ace it" 
    style={{width: props.width}}
    />
  );
}

export default Brain;