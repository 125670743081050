import { GetText } from "../languages";
import { Card, ICardData } from "./card.model";

export class WordAnswerCard extends Card {
    data: IWordAnswerCardData;
    constructor(){
        super()
    }
}

export interface IWordAnswerCardData extends ICardData{
    answer: string;
}
