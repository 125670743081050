import React from 'react';
import styles from './modal-confirmation.component.module.css';
import { on } from 'events';

interface ModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  heading: string;
  message: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onCancel, onConfirm, heading, message }) => {
  if (!isOpen) return null;

    const onConfirmLocal = (e:React.MouseEvent) => {
        e.stopPropagation();
        onConfirm();
    };

    const onCancelLocal = (e:React.MouseEvent) => {
        e.stopPropagation();
        onCancel();
    }

    return (
        <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
            <h2>{heading}</h2>
            <p>{message}</p>
            <button onClick={onConfirmLocal}>Yes</button>
            <button onClick={onCancelLocal}>No</button>
        </div>
        </div>
    );
};

export default Modal;