import { HttpsCallable, connectFunctionsEmulator, getFunctions, httpsCallable  } from 'firebase/functions';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import * as baseLib from '@lenfromkits/firestore-base-lib';

//CRUD operations must be implemented on the server for this client lib to call:
//  CreateDoc
//  UpdateDoc
//  DeleteDoc
//  UpdateSummary
//  GetDocs (all or by some fields)

export interface InitOptions{
    runLocally:boolean;
    localFunctionsPort?: number; 
    localAuthPort?: number;
    localFirestorePort?: number;
}

export interface FirestoreOptions{
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;     
}

type DocumentTypesDictionary = {
    [key: string]: DocumentDictionaryType;
}

type DocumentDictionaryType = {
    cachedItems: DocumentDictionary;
    type: baseLib.CollectionType;
    isEntireTable?: boolean;
}

type DocumentDictionary = {
    [key: string]: baseLib.BaseFirestoreDoc;
}

export class FirestoreClientService implements baseLib.IDataAccess{
    private static instance: FirestoreClientService;
    private _documentCache:DocumentTypesDictionary = {};
    private _client:baseLib.IBaseClient;
    private _clientsCollectionName:string;
    public readonly emitter = baseLib.mitt<baseLib.Events>();

    /**
     * constructor needs to be private because it needs to load the client from the database right away but that's an async operation,
     * so must call the async static Init instead.
     * @param config 
     * @param schema 
     * @param accessToken 
     * @param client 
     * @returns 
     */
    constructor(private config: InitOptions, schema:baseLib.schema.Schema, private accessToken:string, clientsCollectionName:string) {
        try{
        
                if (!FirestoreClientService.isInitialized){
                throw new Error("FirestoreClientService.InitFirebase() must be called first.");
            }

            
            config.localFunctionsPort ||= 5001;
            config.localAuthPort ||= 9099;
            config.localFirestorePort ||= 8080;
            config.runLocally ||= false;
            
            this.schema = schema;
            this._clientsCollectionName = clientsCollectionName;

            console.log('FirestoreClientService constructor, config:', config);
            
            
            if (FirestoreClientService.instance){
                console.log('FirestoreClientService already initialized. Any new config params ignored.');
                return FirestoreClientService.instance;
            }
            FirestoreClientService.instance = this;

            this.GetAllDocs(clientsCollectionName).then(clients => {
                if (clients.length == 0){
                    throw new Error("The client record was not found in the database.");
                }
                this._client = clients[0] as baseLib.IBaseClient;

                //now we're ready to roll.
                this.emitter.emit('userLoggedIn', {client:this._client});
            }).catch(error => {
                console.error('FirestoreClientService constructor error:', error);
                throw error;
            });
        }
        catch (error){
            console.error('FirestoreClientService constructor error:', error);
            throw error;
        }
    }

    public static isInitialized;
    private static _app;
    public static InitFirebase(config: FirestoreOptions):void{
        // Initialize Firebase
        this._app = initializeApp(config);
        const analytics = getAnalytics(this._app);

        this.isInitialized = true;
    }

    public get client():baseLib.IBaseClient {
        return this._client;
    }

    private GetCacheDictionary(type:baseLib.CollectionType):DocumentDictionaryType{
        let result:DocumentDictionaryType = this._documentCache[type];
        if (result == null){
            result = {type:type, cachedItems:{}};
            this._documentCache[type] = result;
        }
        return result;
    }


    /**
     * If the client code creates a brand new doc, it gets added to the cache here when updated.
     * 
     * @param doc 
     */
    private AddDocToCache(doc:baseLib.BaseFirestoreDoc):void{

        //kinda need to ensure it has an ID
        if (doc.data.id == null || doc.data.id == ''){
            throw new Error("The document.data does not have an id.");
        }

        let collectionDict = this.GetCacheDictionary(doc.collectionType);
        collectionDict.cachedItems[doc.data.id] = doc;//if it already exists, it will be replaced but probably shouldn't be and would likely be the same instance anyway
    }

    /**
     * Wrap the raw IDocumentData in a BaseFirestoreDoc and add it to the cache, returning the wrapping BaseFirestoreDoc.
     * If it's already in the cache, just return the existing BaseFirestoreDoc.
     * @param type 
     * @param data 
     * @returns Wraps the raw IDocumentData object in a BaesFirestoreDoc and returns it (or grabs it from the cache if it already exists) 
     */
    private InstantiateDoc(type:baseLib.CollectionType, data:baseLib.IDocumentData):baseLib.BaseFirestoreDoc{
        //kinda need to ensure it has an ID
        if (data.id == null || data.id == ''){
            throw new Error("The document.data does not have an id.");
        }

        let collectionDict = this.GetCacheDictionary(type);
        let doc = collectionDict.cachedItems[data.id];
        
        //every item we store in the cache is wrapped by a doc wrapper.  We NEVER repace a doc wrapper. Ever.
        //if a doc is reloaded, we just replace the doc inside the wrapper.
        //client pages ought to only ever ref the doc wrapper, not the doc itself, otherwise it will
        //end up with a stale version of the doc if it is refreshed and reloaded.
        if (doc == null){

            doc = baseLib.BaseFirestoreDoc.Instantiate(type, this);

            //copy all the data into the class
            doc.SetData(data);

            //lets us know at time of update, if it's new or existing
            doc.existingOnServer = true;

            //store it in the cache
            collectionDict.cachedItems[doc.data.id] = doc;
        }
        else{
             //we can't go replacing the data object itself since it has references to it
            //all over the place, and even worse, references to its children or grandchildren, etc.
            //All those references will end up holding a separate older copy in ram.
            //instead, keep all the "objects" and "arrays" within the object the same, just update all the field values.
            doc.SetData(data);
        }

        doc.data = baseLib.GetDataProxy(doc.data) as baseLib.IDocumentData;

        //also, anytime a record is loaded, make sure it's parent's summary reflects its current state
        // (if the parent is in the cache)
        this.UpdateParentSummary(doc);
        return doc; 
    }    


    private async Wait(milliseconds:number):Promise<void>{
        return new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, milliseconds);
        });
    };

    /**
     * we need to limit calls to the server to only one per x amount of time. 
     * this records the call to this function as the last time the server was called and 
     * waits until x amount of time has passed before calling the server again.
     */
    private lastTimeServerCalled:Date = new Date();
    private minTimeBetweenServerCalls:number = 20;//20 would be max 50 calls per second
    private async WaitSinceLastServerCall():Promise<void>{
        let now = new Date();
        let diff = now.getTime() - this.lastTimeServerCalled.getTime();
        if (diff < this.minTimeBetweenServerCalls){
            await this.Wait(this.minTimeBetweenServerCalls - diff);
        }
        this.lastTimeServerCalled = new Date();
    }    

    private async GetHttpsCallable(functionName:string):Promise<HttpsCallable<unknown, unknown>>{
        await this.WaitSinceLastServerCall();
        return FirestoreClientService.GetHttpsCallable(functionName, this.config);
    }

    /**
     * We have a static method so that it can be called before the firestore client instance is created to load the Client,
     * since the constructor needs the Client
     * @param functionName 
     * @param config 
     * @returns 
     */
    private static GetHttpsCallable(functionName:string, config:InitOptions):HttpsCallable<unknown, unknown>{
        const functions = getFunctions(this._app);
        if (config.runLocally){
            console.log('Running locally', config);
            
            connectFunctionsEmulator(functions, "localhost", config.localFunctionsPort);
        }
        const getFunction = httpsCallable(functions, functionName);
        return getFunction;
    }

    private GetItemFromCacheById(collection:baseLib.CollectionType, id:string):baseLib.BaseFirestoreDoc{
        let collectionDict = this.GetCacheDictionary(collection);
        return collectionDict.cachedItems[id];
    }

    /**
     * If a GetAllDocs() was called for a collection, then we know we have ALL of them already so no more need to call the 
     * server for anything for that collection.
     * @param collection 
     * @returns 
     */
    private GetItemFromCacheByAllTable(collection:baseLib.CollectionType):baseLib.BaseFirestoreDoc[]{
        let collectionDict = this.GetCacheDictionary(collection);
        if (collectionDict.isEntireTable){
            return Object.values(collectionDict.cachedItems);
        }
        return null;
    }

    public async GetAllDocs(collection:baseLib.CollectionType):Promise<baseLib.BaseFirestoreDoc[]>{
        let results = await this.GetDocs(collection, []);//query with no filters (it'll still filter by clientId)

        //and mark that we have the entire table in the cache
        let collectionDict = this.GetCacheDictionary(collection);
        collectionDict.isEntireTable = true;
        
        return results;

    }

    public async GetDocById(collection:baseLib.CollectionType, id:string):Promise<baseLib.BaseFirestoreDoc>{
        //check if in the cache already
        let doc = this.GetItemFromCacheById(collection, id);
        if (doc != null){
            return doc;
        }
        
        let results = await this.GetDocs(collection, [{fieldPath:'id', opStr:'==', value:id}]);
        if (results.length == 0){
            return null;
        }
        return results[0];
    }

    private FilterList(list:baseLib.BaseFirestoreDoc[], filters:baseLib.QueryFilter[]):baseLib.BaseFirestoreDoc[]{

        //filter the list based on all the different comparison types like:  WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'not-in' | 'array-contains-any';
        return list.filter(doc => {
            for (let filter of filters){
                let value = doc.data[filter.fieldPath];
                switch (filter.opStr){
                    case '<':
                        if (value >= filter.value){
                            return false;
                        }
                        break;
                    case '<=':
                        if (value > filter.value){
                            return false;
                        }
                        break;
                    case '==':
                        if (value != filter.value){
                            return false;
                        }
                        break;
                    case '!=':
                        if (value == filter.value){
                            return false;
                        }
                        break;
                    case '>=':
                        if (value < filter.value){
                            return false;
                        }
                        break;
                    case '>':
                        if (value <= filter.value){
                            return false;
                        }
                        break;
                    case 'array-contains':
                        if (!(value instanceof Array) || !value.includes(filter.value)){
                            return false;
                        }
                        break;
                    case 'in':
                        if (!(filter.value instanceof Array) || !filter.value.includes(value)){
                            return false;
                        }
                        break;
                    case 'not-in':
                        if (!(filter.value instanceof Array) || filter.value.includes(value)){
                            return false;
                        }
                        break;
                    case 'array-contains-any':
                        if (!(value instanceof Array) || !(filter.value instanceof Array) || !value.some(x=>filter.value.includes(x))){
                            return false;
                        }
                        break;                          
                }
            }
            return true;
        });
    }

    public async GetDocs(collection:baseLib.CollectionType, filters:baseLib.QueryFilter[]):Promise<baseLib.BaseFirestoreDoc[]>{
        try{
            let cachedAll = this.GetItemFromCacheByAllTable(collection);
            if (cachedAll != null){
                //we have the entire list of the collection in the cache, so just filter it
                //and avoid the trip to the server.
                if (filters == null || filters.length == 0){
                    return cachedAll;
                }
                else{
                    return this.FilterList(cachedAll, filters);
                }
            }

            const serverCall = await this.GetHttpsCallable('GetDocs');
            let response = await serverCall({token: this.accessToken, type: collection, fields:filters});
            let result = response.data as baseLib.ServerFunctionReponse;
            if (!result.success){
                throw new Error(result.errorMessage);
            }

            let datas = result.data as baseLib.IDocumentData[];
            let results = datas.map(data => {
            // let newDoc:baseLib.BaseFirestoreDoc = new type();
                //wrap the data in a proxy object so we can detect changes to any fields, which will call the DataProxyCallback function
            // newDoc.data = GetDataProxy(doc, newDoc.DataProxyCallback);
            //  return newDoc;
                return this.InstantiateDoc(collection, data);
            });

            return results;
            }
        catch (error){
            console.error('GetDocs error:', error);
            throw error;
        }
    }

    private async CreateDoc(doc:baseLib.BaseFirestoreDoc):Promise<void>{
        const collection = doc.collectionType;
        this.AddDocToCache(doc);

        const serverCall = await this.GetHttpsCallable('CreateDoc');
        let json = doc.ToJson();//todo: stop converting to json and back. recurse and build the object to send.
        let obj = JSON.parse(json);        
        let response = await serverCall({token: this.accessToken, type: collection, data:obj});
        let result = response.data as baseLib.ServerFunctionReponse; 
        if (!result.success){
            throw new Error(result.errorMessage);
        }

        let updatedVersonOfDoc = result.data as baseLib.IDocumentData;
        doc.SetData(updatedVersonOfDoc);

        this.UpdateParentSummary(doc);

    }

    /**
     * This handles all create, update and delete operations for a document, 
     * based it isn't updateState.
     * @param doc 
     */
    public async UpdateDoc(doc:baseLib.BaseFirestoreDoc):Promise<void>{
        const collection = doc.collectionType;

        switch (doc.updateState){
            case baseLib.eUpdateState.Delete:
                await this.DeleteDoc(doc);
                break;            

            case baseLib.eUpdateState.Create:
                await this.CreateDoc(doc);
                break;

            case baseLib.eUpdateState.Update:
                const serverCall = await this.GetHttpsCallable('UpdateDoc');
                //only send the 'data' no the doc.
                //todo: stop converting to json and back. recurse and build the object to send.
                let json = doc.ToJson();//(returns just the 'data') 
                let obj = JSON.parse(json);
                let response = await serverCall({token: this.accessToken, type: collection, data:obj});
                let result = response.data as baseLib.ServerFunctionReponse;
                let updatedVersonOfDoc = result.data as baseLib.IDocumentData;
                doc.SetData(updatedVersonOfDoc);
                this.UpdateParentSummary(doc);
                break;
        }
    }

    private UpdateParentSummary(doc:baseLib.BaseFirestoreDoc, deleteIt?:boolean):void{
        let collection = doc.collectionType;

        //and update the parent's summary, if the parent has a summary for this AND ALSO if we have the parent in the cache
        // (the server also does this for real in the database when we call CreateDoc)
        let schema = baseLib.schema.definitions[collection];
        if (schema == null){
            debugger;
            throw new Error("The schema is not set up correctly.  The collection " + collection + " does not exist");
        }
        if (schema.parentType != null){
            let parentSchema = baseLib.schema.definitions[schema.parentType];
            if (parentSchema == null){
                throw new Error("The schema is not set up correctly.  The parent collection " + schema.parentType + " does not exist");
            }
            //it has a parent, so update the parent's summary
            let summarySchemaInParentForThisChild = parentSchema.summaries.find(x=>x.childType == collection);
            if (summarySchemaInParentForThisChild != null){
                //and there's actually a summary in the parent for this child collection
                let parentDoc = this.GetItemFromCacheById(parentSchema.type, doc.data.parentId);
                if (parentDoc != null){
                    if (deleteIt){
                        //remove the info.summaryRecord from the parent doc summaries
                        //todo:  add some sort of callback to the schema for the parent doc when we delete a summary item
                        //   in case there's some sort of aggregate value that needs to be updated.
                        let summaryItems = parentDoc.data[summarySchemaInParentForThisChild.fieldName];
                        if (summaryItems != null){
                            let summaryItem = summaryItems.find(x=>x.id == doc.data.id);
                            if (summaryItem != null){
                                let index = summaryItems.indexOf(summaryItem);
                                if (index >= 0){
                                    summaryItems.splice(index, 1);
                                }
                            }
                        }                        
                    }
                    else{
                        //the parent doc is here sitting around in the cache. add the new child to the summary
                        baseLib.CopyMapFields(summarySchemaInParentForThisChild, parentDoc.data, doc.data);
                    }
                }
            }
        }
    }

    private async DeleteDoc(doc:baseLib.BaseFirestoreDoc):Promise<void>{
        const collection = doc.collectionType;

        //remove it from the cache
        let dict = this.GetCacheDictionary(collection);
        delete dict.cachedItems[doc.data.id];

        //delete it from the server
        const serverCall = await this.GetHttpsCallable('DeleteDoc');
        //send the whole doc, since needs the parentId, and who knows what else.
        let response = await serverCall({token: this.accessToken, type: collection, data:doc.data});
        let result = response.data as baseLib.ServerFunctionReponse;
        if (!result.success){
            throw new Error(result.errorMessage);
        }

        this.UpdateParentSummary(doc, true);
    }

    public GetAccessLevel():Promise<baseLib.AccessLevel>{
        return null;
    }

    public get schema():baseLib.schema.Schema{
        return baseLib.schema.definitions;//asumes it was "set" first
    }
    public set schema(value:baseLib.schema.Schema){
        baseLib.schema.SetSchema(value);
    }

}
