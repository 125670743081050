import React from "react";
import styles from "./moto.component.module.css";
import Brain from "../brain/brain.component";

interface MotoComponentProps {
  isSmall?:string
}

const Moto: React.FC<MotoComponentProps> = (props) => {
  return (
    <div className={styles.main}
      style={{'--textSize': props.isSmall == 'true' ? '1rem' : '1.5rem'} as React.CSSProperties}
    >
        <span>Read it.</span> 
        <span>Learn it.</span> 
        <span className={styles.aceIt}>Ace it.</span>
        <span className={styles.brain}><Brain width={props.isSmall=='true' ? '2rem' : '3rem'}></Brain></span>
    </div>
  );
}

export default Moto;