import { BaseFirestoreDoc, IDocumentData } from "@lenfromkits/firestore-base-lib";
import { IWordAnswerCardData } from "./wordAnswerCard.model";

export abstract class Card extends BaseFirestoreDoc {
    data: ICardData;

    /**
     * returns the appropriate description for the card type. Read-only.
     */
    get description():string{
        switch(this.data.cardType){
            case CardType.WordAnswer:
                return this.data.question;

            case CardType.MultipleChoice:
                return this.data.question;

            case CardType.Diagram:
                return this.data.question;
        }
    }
}

export enum CardType{
    WordAnswer = 0,
    MultipleChoice = 1,
    Diagram = 2,
}

export interface ICardData extends IDocumentData{
    name: string;
    question: string;
    cardType: CardType;
}

export interface ICardSummary extends IDocumentData{
    name: string;
    cardType: CardType;
}