
/**
 * this is critical to export and set into the firestore-client-lib since all the models' metadata are registered here at runtime
 */
export {schema} from '@lenfromkits/firestore-base-lib';

export * from './deck.model';
export * from './client.model';
export * from './profile.model';
export * from './types';
export * from './languages';
export * from './cards/card.model';
export * from './cards/wordAnswerCard.model';
export * from './cards/diagramCard.model';
export * from './cards/multipleChoiceCard.model';

