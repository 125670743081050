import { Schema } from "./schema";
import { BaseFirestoreDoc } from "./baseFirestoreDoc";
import {CollectionType} from "./baseFirestoreObjects";
import {Emitter} from "./customMitt";

//export type DataProxyCallback = (root: object, nodeChanged: object, fieldName:string, valueBefore: any, valueAfter: any) => any|undefined;

export interface IDocument{
    data:IDocumentData;
    collectionType:CollectionType;
    updateState:eUpdateState;
}

export interface ISummaryData{
    id:string;
}

export interface IProxyData{
    __meta?: IDataMetaFields;
}

export interface IDocumentData{
    id:string;
    parentId:string;
    clientId:string;
    created?:Date;
    modified?:Date;
}

export type DataProxyCallback = (data:IDataBindCallback)=>void;
export type ForceUpdateScreenCallback = ()=>void;

export interface IDataProxyCallbackData{
    nodeChanged: IProxyData;
    fieldName:string;
    valueBefore: any;
    valueAfter: any;
}
export interface IDataProxyCallbackDataWithReturn extends IDataProxyCallbackData{
    returnAction?: any|undefined;
}

export interface IDataBindCallback extends IDataProxyCallbackDataWithReturn{
    allowsReturnValueInterception?:boolean;
}


export type DataProxyCallbackEvents = {
    dataChanged: IDataProxyCallbackDataWithReturn;
    dataChangedIncludingChildren: IDataProxyCallbackData;
}

export interface IDataMetaFields{
    dirty:boolean;
    parent:IProxyData;
    IsDirty():boolean;
    ClearDirty():void;
    GetRoot():IProxyData;
    emitter:Emitter<DataProxyCallbackEvents>;
    ToJson():string;
    GetRawObjectNoProxy():object;
}

export interface IBaseClientData extends IDocumentData{
    email:string;
}

export interface IBaseClient extends BaseFirestoreDoc{
    /**
     * this is basically just me, the developer and owner of the app
     */
    isSuperAdmin:boolean;

    /**
     * this is the owner of the client and it's their email on the client as the main email.
     */
    isOwner:boolean;
    
    get data():IBaseClientData;
    set data(value:IBaseClientData); 
}


export enum eUpdateState{
    Create = 1,
    Update = 2,
    Delete = 3
}


export type AccessLevel = {
    isOwner:boolean;
    isSuperAdmin:boolean;
}


/**
 * From Firebase, redefined here for use in client apps where they don't have a ref to the firebase library,
 * but will be passing this data to firebase via other libraries.
 * Filter conditions in a `Query.where()` clause are specified using the
 * strings '<', '<=', '==', '!=', '>=', '>', 'array-contains', 'in', 'not-in',
 * and 'array-contains-any'.
 */
export type WhereFilterOp =
    | '<'
    | '<='
    | '=='
    | '!='
    | '>='
    | '>'
    | 'array-contains'
    | 'in'
    | 'not-in'
    | 'array-contains-any';


export type QueryFilter = {
    fieldPath: string,
    opStr: WhereFilterOp,
    value: any        
}

export type Events={
    userLoggedIn: {client:IBaseClient};
}

export interface IUser{
    email:string;
    firstName:string;
    lastName:string;
}


export interface IDataAccess{
    /**
     * Handles Create, Update and Delete based on the updateState of the doc.
     * @param doc 
     */
    UpdateDoc(doc:BaseFirestoreDoc):Promise<void>;
    GetAccessLevel():Promise<AccessLevel>;
    GetAllDocs(collection:CollectionType):Promise<BaseFirestoreDoc[]>;
    GetDocs(collection:CollectionType, filters:QueryFilter[]):Promise<BaseFirestoreDoc[]>;
    GetDocById(collection:CollectionType, id:string):Promise<BaseFirestoreDoc>;
    get schema():Schema;
    set schema(value:Schema);
    get client():IBaseClient;
    get emitter():Emitter<Events>;
}

export type ServerFunctionReponse = {
    success:boolean;
    errorMessage?:string;
    data?:IDocumentData[] | IDocumentData;
}
