import { getAuth, signInWithPopup, GoogleAuthProvider, 
  signInWithEmailAndPassword, connectAuthEmulator, signOut, 
  signInWithRedirect,
  getRedirectResult} from "firebase/auth";

import * as baseLib from '@lenfromkits/firestore-base-lib';
import { FirestoreClientService } from "./firestoreClient";

type Events = {
  userLoggedIn: baseLib.IUser;
}

type Credentials = {
  email:string,
  accessToken:string
}

export interface AuthInitOptions{
    runLocally:boolean;
    localAuthPort:number;
}

//define an interface for a callback function that passes the User object
export interface UserCallback{
  (user:baseLib.IUser):void;
}

export class AuthService { 
  _isLoggedIn = false;
  accessToken: string;
  private credentials:Credentials;
  private _user:baseLib.IUser;
  public emitter = baseLib.mitt<Events>();

  constructor(private options:AuthInitOptions){ 

    if (FirestoreClientService.isInitialized == false){
      throw new Error("FirestoreClientService.InitFirebase() must be called first.");
    }

    options.runLocally ||= false;
    options.localAuthPort ||= 9099;

    let credentials = localStorage.getItem('credentials');
    if (credentials != null){
      this.credentials = JSON.parse(credentials);
      this.accessToken = this.credentials.accessToken;
      this._isLoggedIn = true;
    } 
  }

  get isLoggedIn(){
    return this._isLoggedIn;
  }

  async LogOut(){
    const auth = getAuth();
    this._isLoggedIn = false;
    this.credentials = null;
    this.accessToken = null;
    await signOut(auth);

    if (this.options.runLocally){
      // Reset emulator configuration
      (auth as any)._canInitEmulator = true;     
    }
  }

  private TriggerLoginCallback(user:baseLib.IUser){
    this.emitter.emit('userLoggedIn', user);
  }

  async LoginEmail(email:string, password:string){
    try{
        const auth = getAuth();
        (auth as any)._canInitEmulator = true;

        let result = await signInWithEmailAndPassword(auth, email, password);
        // This gives you a Google Access Token. You can use it to access the Google API.
        this.accessToken = (result.user as any).accessToken;
        this.credentials = {email:email, accessToken:this.accessToken};
        localStorage.setItem('credentials', JSON.stringify(this.credentials));
        this._isLoggedIn = true;
        this._user = {email:email, firstName:'', lastName:''};
        this.TriggerLoginCallback(this._user);

    }
    catch(error) {
        // Handle Errors here.
        //const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        throw new Error(errorMessage);
    }
  } 

 
  async LoginGoogleResult(){
    const auth = getAuth();
    let result = await getRedirectResult(auth);
    if (result == null || result.user == null){
      return;
    }

    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    this.accessToken = credential.accessToken;
    // The signed-in user info.
    this.credentials = {email:result.user.email, accessToken:this.accessToken};
    localStorage.setItem('credentials', JSON.stringify(this.credentials));
    this._isLoggedIn = true;
    this._user = {email:this.credentials.email, firstName:'', lastName:''};
    this.TriggerLoginCallback(this._user);
  }

  async LoginGoogleRedirect(){
    try{
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        if (this.options.runLocally){
          connectAuthEmulator(auth, "http://127.0.0.1:" + this.options.localAuthPort);
        }
        return signInWithRedirect(auth, provider)
    }
    catch(error) {
        // Handle Errors here.
        //const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        throw new Error(errorMessage);
    }
  } 

  async LoginGoogle(){
    try{
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        if (this.options.runLocally){
          console.log('connecting to local auth emulator', this.options);
          connectAuthEmulator(auth, "http://127.0.0.1:" + this.options.localAuthPort);
        }
        let result = await signInWithPopup(auth, provider)
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        this.accessToken = credential.accessToken;
        // The signed-in user info.
        this.credentials = {email:result.user.email, accessToken:this.accessToken};
        localStorage.setItem('credentials', JSON.stringify(this.credentials));
        this._isLoggedIn = true;
        this._user = {email:this.credentials.email, firstName:'', lastName:''};
        this.TriggerLoginCallback(this._user);
        // IdP data available using getAdditionalUserInfo(result)
    }
    catch(error) {
        // Handle Errors here.
        //const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        throw new Error(errorMessage);
    }
  }

  Logout(){
    this._isLoggedIn = false; 
  }


}
