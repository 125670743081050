import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './hamburger.menu.module.css';
import { NavLink } from 'react-router-dom';

// Define interface for menu items
interface MenuItem {
  text: string;
  url: string;
}

interface HamburgerMenuProps {
  menuItems: MenuItem[];
  headerWrapperRef: React.MutableRefObject<HTMLDivElement>;
}

const HeaderHamburgerMenu: React.FC<HamburgerMenuProps> = ({ menuItems, headerWrapperRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if ((e.target as HTMLElement).closest(`.${styles.hamburgerMenuContainer}`) === null) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const dropdown = isOpen && ReactDOM.createPortal(
    <div className={styles.dropdownMenu}>
    {menuItems.map((item, index) => (
        <NavLink to={item.url} key={index} className={styles.menuItem}>
          {item.text}
        </NavLink>
    ))}
  </div>
  , headerWrapperRef.current);

  return (
    <div className={styles.hamburgerMenuContainer}>
      <div className={styles.hamburgerIcon} onClick={toggleMenu}>
        {/* Add an icon or custom HTML for the hamburger icon here */}
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
      {dropdown}

    </div>
  );
};

export default HeaderHamburgerMenu;
