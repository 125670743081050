import React from "react";
import styles from "./feature.card.module.css";
import { useNavigate } from "react-router-dom";

interface FeatureCardProps {
  children: React.ReactNode;
  title: string;
  background: '1' | '2' | '3';
  url: string;
}

const FeatureCard: React.FC<FeatureCardProps> = (props) => {
  let backgroundImageUrl: string;
  let navigate = useNavigate();

  switch (props.background) {
    case '1':
      backgroundImageUrl = "url('/images/backgrounds/card2.jpg')";
      break;
    case '2':
      backgroundImageUrl = "url('/images/backgrounds/card3.jpg')";
      break;
    case '3':
      backgroundImageUrl = "url('/images/backgrounds/card1.jpg')";
      break;
    default:
      backgroundImageUrl = "url('/images/backgrounds/card1.jpg')";
      break;
  }

  const handleClick = () => {
    navigate(props.url);
  };

  return (
    <div 
      className={styles.featureCard}
      style={{'--featureCardUrl': backgroundImageUrl} as React.CSSProperties}
      onClick={handleClick}
    >
      
      <h2>{props.title}</h2>
      <div className={styles.content}>
      {props.children}
      </div>
    </div>
  );
}


export default FeatureCard;