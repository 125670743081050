
export function GenerateGuid(includeCurlyBraces:boolean = false):string{
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    if (includeCurlyBraces){
        return '{' + uuid + '}';
    }
    else{
        return uuid;
    }
}


/**
 * If the data is wrapped in a proxy, this will return the inner data object without the proxy.
 * If not wrapped in a proxy, it'll just return the data.  This method needs to be called recursively in order
 * to remove all the proxies from the entire hierarchy of data.
 * @returns The data without any proxy wrapping it. 
 */
export function GetDataProxyTarget(obj:object): object {
    return (obj && obj['__testIfHasProxy']) || obj;//from gpt
}

export function Log(msg:string, type:string, logSubTypeText:string=null, data:any = null){
    console.log(type, msg, data, logSubTypeText);
}
  

/**
 * This function generates a random base-36 string of 5 characters. 
 * This should provide over 60 million unique IDs (36^5)
 */
export function GenerateShortId():string{
    //number of millisends since I was born
    let time = Date.now()
    let strTime = ToBase95(time);
    //plus a bit of a random number
    let rand = Math.round(Math.random() * 1000000);
    let strRand = ToBase95(rand);
    //the first two characters of the time are always the same so we can drop them
    return strTime.substring(1, strTime.length-1) + strRand;
}


/**
 * To and From base 95 converts a number to base 95 (as apposed to 2,10,16 or 36). 
 * So it's a very short string representation of a number.  This is useful for generating short IDs.
 */
export function ToBase95(num: number): string {
    let result = '';
    do {
        result = baseChars[num % 95] + result;
        num = Math.floor(num / 95);
    } while (num > 0);
    return result;
}
const baseChars = Array.from({length: 95}, (_, i) => String.fromCharCode(i + 32));


export function FromBase95(str: string): number {
    return Array.from(str).reverse().reduce((prev, curr, i) => prev + baseChars.indexOf(curr) * Math.pow(95, i), 0);
}

export async function Wait(milliseconds:number):Promise<void>{
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, milliseconds);
    });
}



export function ConvertFuckingFirebaseTimestampsToDate(obj:any): {date?:Date, isDate:boolean}{
    if (obj != null && typeof obj === 'object' && obj._nanoseconds != null && obj._seconds != null) {
      return {isDate:true, date: new Date(obj._seconds * 1000 + Math.round(obj._nanoseconds / 1000000))};
    }
    else if (obj != null && typeof obj == 'string' && obj.indexOf(':') > -1){
        //but also it could be a normal date string.
        let date = new Date(obj);
        if (!isNaN(date.getTime())){
            return {isDate:true, date};
        }
    }

    return {isDate:false};
}
