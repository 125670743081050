import React, { useRef } from "react";
import ReactDOM from "react-dom";
import Moto from "../moto/moto.component";
import styles from "./header.component.module.css";
import HamburgerMenu from "../menu/main.menu";
import HeaderHamburgerMenu from "./hamburger.menu";

interface MenuItem {
    text: string;
    url: string;
}
  
interface HeaderProps {
  menuItems: MenuItem[];
  pageTitle: string;
}

const Header: React.FC<HeaderProps> = ({menuItems, pageTitle}) => {
  let headerWrapperRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={headerWrapperRef}>
    <header className={styles.main}>
        <span><HeaderHamburgerMenu 
        menuItems={menuItems}
        headerWrapperRef={headerWrapperRef}
        /></span>
      <span className={styles.logoWords}>Examinarium</span>
      <span className={styles.moto}><Moto isSmall="true"/></span>
      {
        ReactDOM.createPortal(
          <span className={styles.pageTitle}>{pageTitle}</span>
        , document.body)
      }
    </header>
    </div>
  );
};

export default Header;