import { FirestoreService } from './firestore.service';

export default class Globals{
    private static _firebaseService:FirestoreService;
    public static get firebaseService():FirestoreService {
        if (!this._firebaseService){
            this._firebaseService = new FirestoreService();
        }
        return this._firebaseService;
    }

}