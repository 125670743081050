import { IDocumentData, ISummaryData } from "./interfaces";
import { IMapCustomCodeSchema, IMapSchema, IMapSchemaSameName, ISummarySchema } from "./schema";

/**
 * this copies field values form teh child to the parent's summary record for this type of child.
 * Creates the summary record if it doesn't exist.
 * @param summarySchema 
 * @param parentData 
 * @param childData 
 */
export  function CopyMapFields(summarySchema:ISummarySchema, parentData:IDocumentData, childData:IDocumentData){
    //grab the field on the parent doc that holds the list of child summary records (it's an array)
    let summaryListData = parentData[summarySchema.fieldName] as ISummaryData[];
    if (summaryListData == null){
        summaryListData = [];
        parentData[summarySchema.fieldName] = summaryListData;
    }

    //get or create that summary record
    let summaryItem = summaryListData.find(s=>s.id === childData.id);
    if (summaryItem == null){
        summaryItem = {id : childData.id};//the summary item shares the same id as the child doc
        summaryListData.push(summaryItem);
    }

    //map fields over from the child to the parent summary
    summarySchema.maps.forEach(map=>{
        //what type of map is this?  Just cast to our various typescript types so we can look for fields that have values to ID the type
        let childSummary = map as ISummarySchema;
        let fieldMap = map as IMapSchema;
        let fieldMapSameName = map as IMapSchemaSameName;
        let customCode = map as IMapCustomCodeSchema;
        if (childSummary.childType != null){
            //this is a recursion. A child summary of the summary.  So we need to call this function again.
            //the 'childData' is still just the same child data and accessing this data probably involves a query to the db for some
            //grandchild records, so the maps on it should use custom code, otherwise it would be just grabbing from the same one childData
            //which means we don't really need to do this recursion on a child summary
            CopyMapFields(childSummary, summaryItem as IDocumentData, childData);
        }
        else if (fieldMap.parentName != null){
            //copy the value from the child to the parent summary. Here they can specify a simple path notation to get to the value
            summaryItem[fieldMap.parentName] = GetValueAtPath(childData, fieldMap.childPath);
        }
        else if (fieldMapSameName.fieldName != null){//must be checked AFTER childSumamry since has same 'fieldName' property
            //copy the value from the child to the parent summary (same field name on both)
            summaryItem[fieldMapSameName.fieldName] = childData[fieldMapSameName.fieldName];
        }
        else if (customCode.customCode != null){
            //call the custom code to do the mapping
            customCode.customCode({parentData, childData, summaryItem, summarySchema});
        }
        else{
            throw new Error("Unknown map type on summary schema: " + summarySchema.fieldName);
        }
    });

    //ensure no value of 'undefined' exists on the summary since firebase will refuse it.
    for (let key in summaryItem){
        if (summaryItem[key] === undefined){
            delete summaryItem[key];
        }
    }
}


/**
 * From Gpt. accepts a path to find a field in an object hierarchy - but very very simple, 
 * like 'field1.field2.field3' and it will return the value of that field3 in the object.
 */
function GetValueAtPath(obj: any, path: string): any {
    return path.split('.').reduce((accumulator, currentKey) => {
        return accumulator ? accumulator[currentKey] : undefined;
    }, obj);
}
